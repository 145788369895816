import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot } from '@angular/router';
import { Observable, of } from 'rxjs';
import { AuthorizationService } from './authorization.service';

@Injectable({
  providedIn: 'root'
})
export class RoleGuardService implements CanActivate {

  constructor(private authorizationService: AuthorizationService, private router: Router) { }

  canActivate(
    next: ActivatedRouteSnapshot,
  ): Observable<boolean> | boolean {

    return new Observable<boolean>((observer) => {
      if (sessionStorage.getItem('loggedinUser') != null) {

        let value: boolean = this.authorizationService.hasAccess(next.data.role);
        if (!value) {
          this.router.navigateByUrl('/misc/unauthorized');
        }
        observer.next(value);
      }
    });

  }
}
