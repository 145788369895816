import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

import { LoginComponent } from '../components/login/login.component';
import { CallbackComponent } from '../components/callback/callback.component';

const routes: Routes = [
  {
    path: '',
    redirectTo: 'callback',    
    pathMatch: 'full',
    // component: CallbackComponent
  },
  {
    path: 'callback',
    component: CallbackComponent,     
  },
  {
    path: 'login',
    component: LoginComponent,
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AuthorizationRoutingModule {}