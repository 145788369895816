import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'headerFormat'
})
export class HeaderFormatPipe implements PipeTransform {

  transform(headerName: string): string {
    // headerName = headerName.replace(/([A-Z]+)/g, ",$1").replace(/^/, "");
    // let splittedHeaderName = headerName.split(",");
    // headerName = splittedHeaderName.join(' ').toString();
    // headerName = headerName.charAt(0).toUpperCase() + headerName.slice(1);
    // headerName.replace(/_/g, ' ');
    let fileFormated = headerName.split('\\'); 
    let finalString = fileFormated[fileFormated.length-1];
    return finalString;
  }

}
