import { Injectable } from '@angular/core';
import { MysqlService } from '../../shared/services/mysql.service';
import { Subject, takeUntil } from 'rxjs';
import { CommandType, IRelationalDataDto, ParameterDirection, RelationalDatabases } from '../../shared/model/shared.model';
import { ListeUserDetails } from '../model/listen-db.model';

@Injectable({
  providedIn: 'root'
})
export class ListenDbService {

  protected ngUnsubscribe: Subject<void> = new Subject<void>();
  constructor(private mysqlService: MysqlService) {

  }

  GetUserRoles(email:string) {

    let headerFilteredOptions: IRelationalDataDto = {
      filters: new Array(),
      sorts: new Array(),
      name: '',
      commandType: CommandType.StoredProcedure,
      parameters: [],
      databasename: ''
    };
    
    let listeUserDetails =  new ListeUserDetails();
    listeUserDetails.email = email;
    headerFilteredOptions.databasename = RelationalDatabases.listen_db.toString();
    headerFilteredOptions.name = 'GetListeUserDetails';
    headerFilteredOptions.commandType = CommandType.StoredProcedure;
    headerFilteredOptions.parameters = [
                 { name: 'p_EMAIL', value: email, parameterDirection: ParameterDirection.Input },
                 { name: 'p_ISUSEREXISTS', value: '', parameterDirection: ParameterDirection.Output }
                ];
    headerFilteredOptions.filters = [];
    headerFilteredOptions.sorts = [];
   return this.mysqlService.getFilteredRDSDataWithOutputParam(headerFilteredOptions);
    //   .pipe(takeUntil(this.ngUnsubscribe))
    //   .subscribe((res: any) => {

    //     if (typeof res != 'undefined' && res.outputParamValue === true) {
    //       listeUserDetails.firstName = res.records[0].vorname;
    //       listeUserDetails.lastName = res.records[0].nachname;
    //       listeUserDetails.zid = res.records[0].gid;
    //       listeUserDetails.isreadwriteuser = res.records[0].isreadwriteuser === 1 ? true : false;
    //       listeUserDetails.standort = res.records[0].standort;
    //       listeUserDetails.personaL_NR = res.records[0].personaL_NR;
    //   }

    //     sessionStorage.setItem('listenDBUser', JSON.stringify(listeUserDetails));
    //   });

    // return listeUserDetails;
  }
}
