import { UserResolver } from './main/authorization/services/user.resolver';
import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router'
import { AuthGuardService } from './main/authorization/services/authGuard.service';
import { environment } from '../environments/environment';
import { DevGuardService } from './main/authorization/services/devGuard.service';
import { RoleGuardService } from './main/authorization/services/roleGuard.service';
import { NotFoundComponent } from './main/shared/components/not-found/not-found.component';
import { MaintenancePageComponent } from './main/maintenance-page/maintenance-page.component';

const guardService = environment.production ? AuthGuardService : DevGuardService;

const routes: Routes = [
  {
    path: 'adept',
    loadChildren: () =>
      import('./main/adept/modules/adept.module').then((m) => m.AdeptModule),
    resolve: { edaUser: UserResolver },
    // canActivate: [RoleGuardService, guardService], data: {
    //   role: 'adept'
    // }
  },
  {
    path: 'network-data',
    loadChildren: () =>
      import('./main/network-data/modules/network-data.modules').then((m) => m.NetworkDataModule),
    resolve: { edaUser: UserResolver },
    canActivate: [RoleGuardService, guardService], data: {
      role: 'network-data',
    }
  },
  {
    path: 'transformer-cards',
    loadChildren: () =>
      import('./main/transformer-cards/modules/transforming-cards.modules').then((m) => m.TransformerCardsModule),
    resolve: { edaUser: UserResolver },
    canActivate: [RoleGuardService, guardService], data: {
      role: 'transformer-cards',
    }
  },
  {
    path: 'cts-drawings',
    loadChildren: () =>
      import('./main/cts-drawings/components/modules/cts-drawings.modules').then((m) => m.CtsDrawingsRoutingModule),
    resolve: { edaUser: UserResolver },
    canActivate: [RoleGuardService, guardService], data: {
      role: 'cts-drawings',
    }
  },
  {
    path: 'ppt-drawings',
    loadChildren: () =>
      import('./main/ppt-drawings/components/modules/ppt-drawings.modules').then((m) => m.PptDrawingsRoutingModule),
    resolve: { edaUser: UserResolver },
    canActivate: [RoleGuardService, guardService], data: {
      role: 'ppt-drawings',
    }
  },
  {
    path: 'teamcenter-archive',
    loadChildren: () =>
      import('./main/team-center/modules/team-center.modules').then((m) => m.TeamCenterModule),
    resolve: { edaUser: UserResolver },
    canActivate: [RoleGuardService, guardService], data: {
      role: 'teamcenterarchive',
    }
  },
  {
    path: 'once',
    loadChildren: () =>
      import('./main/once/modules/once.module').then((m) => m.OnceModule),
    resolve: { edaUser: UserResolver },
    canActivate: [RoleGuardService, guardService], data: {
      role: 'once'
    }
  },
  {
    path: 'agt-portal',
    loadChildren: () =>
      import('./main/agt-portal/modules/agt-portal.module').then((m) => m.AgtPortalModule),
    resolve: { edaUser: UserResolver },
    canActivate: [RoleGuardService, guardService], data: {
      role: 'agt-portal'
    }
  },
  {
    path: 'admin',
    loadChildren: () =>
      import('./main/admin/modules/admin.module').then((m) => m.AdminModule),
    resolve: { edaUser: UserResolver },
    canActivate: [RoleGuardService, guardService],
    data: {
      role: 'admin'
    }
  },
  {
    path: 'ifs',
    loadChildren: () =>
      import('./main/ifs/modules/ifs.module').then((m) => m.IfsModule),
    resolve: { edaUser: UserResolver },
    canActivate: [RoleGuardService, guardService],
    data: {
      role: 'ifs'
    }
  },
  {
    path: 'misc',
    loadChildren: () =>
      import('./main/misc/modules/misc.module').then((m) => m.MiscModule),
  },
  {
    path: 'listen-db',
    loadChildren: () =>
      import('./main/listen-db/modules/listen-db.module').then((m) => m.ListenDbModule),
    resolve: { edaUser: UserResolver },
    canActivate: [RoleGuardService, guardService], data: {
      role: 'listen-db'
    }
  },
  {
    path: 'modman',
    loadChildren: () =>
      import('./main/modman/modules/modman.module').then((m) => m.ModmanModule),
    resolve: { edaUser: UserResolver },
    canActivate: [RoleGuardService, guardService], data: {
      role: 'modman'
    }
  },
  {
    path: 'ees-archive-system',
    loadChildren: () =>
      import('./main/ees-archive-system/modules/ees-archive.module').then((m) => m.EesArchiveModule),
    resolve: { edaUser: UserResolver },
    canActivate: [RoleGuardService, guardService], data: {
      role: 'ees-archive-system'
    }
  },
  {
    path: 'idms',
    loadChildren: () =>
      import('./main/idms/modules/idms.module').then((m) => m.IdmsModule),
    resolve: { edaUser: UserResolver },
    canActivate: [RoleGuardService, guardService], data: {
      role: 'idms'
    }
  },
  {
    path: 'nam-css',
    loadChildren: () =>
      import('./main/nam-css/modules/nam.module').then((m) => m.NamModule),
    resolve: { edaUser: UserResolver },
    canActivate: [RoleGuardService, guardService], data: {
      role: 'nam-css'
    }
  },
  {
    path: 'ims',
    loadChildren: () =>
      import('./main/ims/modules/ims.module').then((m) => m.ImsModule),
    resolve: { edaUser: UserResolver },
    canActivate: [RoleGuardService, guardService], data: {
      role: 'ims'
    }
  },
  {
    path: 'ppt-drawings',
    loadChildren: () =>
      import('./main/ppt-drawings/modules/ppt-drawings.module').then((m) => m.PptDrawingsModule),
    resolve: { edaUser: UserResolver },
    canActivate: [RoleGuardService, guardService], data: {
      role: 'ppt-drawings',
    }
  },
  {
    path: 'maintenance-page',
    component: MaintenancePageComponent
  },
  {
    path: 'not-found',
    component: NotFoundComponent
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule { }
