export interface ISearchDto {
  filters?: IFilterParams[];
  sorts?: ISortFilterParam[];
  mininumNotStrictShouldMatch?: number;
  aggregations?: IAggregationParams[];
  edaUser?: string;
  files?: string[];
  index?: string;
  processType?: ProcessType;
  pdfDto?: IPDFDto;
  limit?: number;
  htmlToPdf?: HtmlToPdf;
  module?: string;
  pageSize?: string;
  mainHeaders?: IMain[];

  //exception -- is only used in FVP
  FindViewPrintFilter?: { DocumentNumber: string[], filterType: string, Description: string, RFlag: string }

  //For RDS
  relationalData?: IRelationalDataDto;
  object?: any;
}


export interface HtmlToPdf {
  template: string;
}

export interface BackArrowRedirect {
  changeTo: string;
  redirect: boolean;
}


export interface mySqlParams {
  dbField: string,
  dbParam: string,
  parameterDirection?: ParameterDirection,
  isPK?: boolean
}

export interface IPDFDto {
  pdfFiles?: IPdfFile[];
  papesrSize: string;
  isPortrait: boolean;
  isPdfInSeparateDocuments: boolean;
}

export interface IPdfFile {
  files?: string[];
  ouputName: string;
}

export enum ProcessType {
  //AsyncDownloads ExcelDownloads
  AsyncDownloads = 0,
  ZipDownloads,
  PdfDownloads,
  HtmlToPdf,
}

export interface ISortFilterParam {
  field: any;
  dir: string;
}

export interface IAggregationParams {
  field: string;
}

export interface IKeyValuePair {
  key: string;
  object: any;
}

export interface IFilterData {
  [key: string]: any;
  isRange?: boolean;
  rangeType?: string;
  rangeProperty?: string;
  affectedProperty?: string;
  notStrict?: boolean;
  filterType?: string;
  isWildCardSearch?: {
    formControlName?: string;
  };
}

export interface IFilterParams {
  field: string;
  value: string;
  filterType: string;
  notStrict?: boolean;
  conditionType?: string;
  isMultiselect?: boolean;
  isRange?: boolean;
  isExact?: boolean;
  rangeProperty?: string;
  isCheckingBlank?: boolean;
  isFixedValue?: boolean, // when values must NEVER get erased by filtering
  pageSize?: string // this is actually the count of records and it is used for mysql service
}

export interface IHeader {
  field: string;
  textColor?: string;
  width?: string;
  header: string;
  headerSubtitles?: string[];
  hyperText?: boolean;
  isLink?: boolean;
  hasDetailPanel?: boolean;
  child?: IChildreNames;
  icon?: string;
  needsFormatting?: boolean;
  hasPopUp?: { hasPopUp: boolean; charLimit: number };
  maxCharacters?: number;
  isFixedWidth?: boolean;
  hasDifferentForeColor?: boolean;
  hideFilter?: boolean;
  hideSort?: boolean;
  hasBooleanForeColor?: boolean;
}

export interface IMain extends IHeader {
  class?: string,
  isFixedValue?: boolean
  img?: string;
  isLazy?: boolean;
  isDropDown?: { name: string }[];
  customSortableField?: string;
  isCountries?: boolean;
  isMultiple?: { name: string }[];
  multipleBackUp?: { name: string }[];
  hidden?: boolean;
  isCheckBox?: boolean;
  isChecked?: boolean;
  isZipBox?: boolean;
  isZipChecked?: boolean;
  notForExcel?: boolean;
  onlyForExcel?: boolean;
  imageButton?: boolean;
  isMultilevel?: boolean;
  hasFilter?: boolean;
  subFields?: string[];
  isSpecialEdit?: boolean,
  typeOfForm?: string,
  checkBoxField?: string,
  currentSearchedValue?: string
  previousSearchedValue?: string,
  previousSelectedValue?: [],
  isFrozenColumn?: boolean,
  dropDownFiltered?: boolean,
  /*used for edit table*/
  maxLength?: number,
  isCaseSensitive?: boolean,
  dropDownVariables?: { options: any, name: any, value: any, selectedValue: any },
  isEditable?: boolean,
  selectedKey?: string,
  editBtn?: boolean,
  editPopUpBtn?: boolean,
  deleteBtn?: boolean,
  deleteBtnValidator?: string,
  recursiveBtn?: boolean,
  customBtns?: { tag: string, icon: string, toolTip: string, visible: boolean, conditionIdentifier?: string }[],
  isDropDownEdit?: boolean,
  pipe?: string
  propertyToCheck?: string,
  typeOfSearch?: string,
  showPlaceHolder?: boolean,
  placeHolder?: string,
  panelIsShowing?: boolean
  module?: string,
  attribute?: string,
  title?: string,
  /* */

  // these two in particular are used for findviewprint,
  // they could be usefull on other scenarios if programmed right
  view?: string;
  isDynamicProperty?: boolean;
  //
  link?: string,
  backcolor?: boolean
}

export interface IChild extends IHeader {
  sourceField?: string;
  addSpacesAtThebeginning?: boolean;
  isPopupChild?: boolean;
  filterMatchMode?: string;
  child?: any;
}

export interface IChildreNames {
  label?: string;
  index: string;
  icon?: string;
  class?: string;
  isTree?: boolean;
  headers?: IChild[];
  defaultSort?: ISortFilterParam[];
  defaultFilters?: IFilterParams[];
}

export interface IResponse {
  documents: Array<{
    popUp: boolean;
    positionInTable: number;
    [k: string]: any;
  }>;
  totalHits: number;
  aggregations: { [k: string]: string[] };
  [k: string]: any;
}

export function IResponseAdapter(json: IResponse, rowButton: any = null) {
  let i = 0;
  json.documents.forEach((item) => {
    item.popUp = false;
    item.positionInTable = i++;
    item.checkBox = false;
    item.isHighlighted = false;
    if (rowButton != null) {
      item.RECURSIVE = rowButton;
    }
  });
  return json;
}

export function mySqlAdapter(json: any) {


  if (!json) {
    json = {};
    json.records = [];
    return json;
  }

  let i = 0;
  json.records.forEach((item: any) => {

    item.positionInTable = i++;
    item.select = false;
    item.requiredinput = false;
    item.isHighlighted = false;
    // item.popUp = false;
  });
  return json;
}



export enum FilterTypes {
  startsWith = 'startsWith',
  contains = 'contains',
  notContains = 'notContains',
  endsWith = 'endsWith',
  equals = 'equals',
  notEquals = 'notEquals',
  gte = 'gte',
  lte = 'lte',
  gt = 'gt',
  lt = 'lt',
}


export interface IRelationalDataDto extends ISearchDto {
  databasename?: string,
  name?: string,
  commandType?: CommandType,
  parameters?: IRelationalDataParameter[],
  sorts?: ISortFilterParam[]
  from?: number

}

export interface IRelationalDataParameter {
  name?: string,
  value?: any,
  parameterDirection?: ParameterDirection
}

export interface RelationalDataSearchResults {
  outputParamValue: number;
  records: any[]
}

export enum ParameterDirection {
  Input = 1,
  InputOutput = 3,
  Output = 2,
  ReturnValue = 6,
}

export enum CommandType {
  StoredProcedure = 4,
  Text = 1
}

export enum RelationalDatabases {
  agt_rresi = 'agt_rresi',
  agt_edms = 'agt_edms',
  agt_parts = 'agt_parts',
  agt_sdrl = 'agt_sdrl',
  agt_changemanagement = 'agt_changemanagement',
  listen_db = 'liste',
  eda_users = 'edausers',
  modman = 'ags',
}

export enum jiraMappedComponent {
  adept = "Adept System",
  ifs = "IFS",
  once = "ONCE",
  networkData = "Network Data",
  transformerCards = "Transformer Cards",
  agtPortal = "AGT Portal",
  listenDB = "Listen DB",
  userAdmin = "User Admin",
  teamCenterArchive = "Teamcenter Archive",
  modman = "Modman",
  ees = "CICS - EES Archive",
  idms = "CICS - PNP Archive",
  ppt = "PNP Drawings",
}

export let allLocation: { [k: string]: { image: string, name: string } } = {
  zip: { image: "assets/img/zip.png", name: "zip", },
  rar: { image: "assets/img/zip.png", name: "rar", },
  doc: { image: "assets/img/word.png", name: "word", },
  pdf: { image: "assets/img/pdf.png", name: "pdf", },
  pdfa: { image: "assets/img/pdf.png", name: "pdfa", },
  xls: { image: "assets/img/excel.png", name: "xls", },
  dwg: { image: "assets/img/dwg.png", name: "dwg", },
  tif: { image: "assets/img/tif.png", name: "tif", },
  tiff: { image: "assets/img/tif.png", name: "tiff", },
  stp: { image: "assets/img/tif.png", name: "stp", },
  1: { image: "assets/img/download.png", name: "1", },
  rtf: { image: "assets/img/rtf.png", name: "1", },
  prt: { image: "assets/img/tif.png", name: "prt", },
  nwd: { image: "assets/img/tif.png", name: "nwd", },
  dxf: { image: "assets/img/dxf.png", name: "dxf", },
  x_t: { image: "assets/img/tif.png", name: "x_t", },
  docx: { image: "assets/img/word.png", name: "docx", },
  docm: { image: "assets/img/word.png", name: "docm", },
  xlsx: { image: "assets/img/excel.png", name: "xlsx", },
  s3n: { image: "assets/img/download.png", name: "s3n", },
  acd: { image: "assets/img/download.png", name: "acd", },
  rak: { image: "assets/img/download.png", name: "rak", },
  idf: { image: "assets/img/download.png", name: "idf", },
  sat: { image: "assets/img/download.png", name: "sat", },
  vsd: { image: "assets/img/download.png", name: "vsd", },
  jpg: { image: "assets/img/jpg.png", name: "jpg", },
  '': { image: "assets/img/download.png", name: "", },
  ipt: { image: "assets/img/download.png", name: "ipt", },
  mpp: { image: "assets/img/download.png", name: "mpp", },
  xps: { image: "assets/img/download.png", name: "xps", },
  iam: { image: "assets/img/download.png", name: "iam", },
  csv: { image: "assets/img/download.png", name: "csv", },
  "7z": { image: "assets/img/zip.png", name: "7z", },
  lgx: { image: "assets/img/download.png", name: "lgx", },
  txt: { image: "assets/img/notepad.png", name: "txt", },
  noExtension: { image: "", name: "", },
}

export interface IDownloadFile {
  message?: string,
  path?: string
}