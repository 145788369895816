import { AuthorizationService } from './../../services/authorization.service';

import { EDAUser, UserInfo } from 'src/app/main/admin/models/admin.model';
import { UtilityService } from 'src/app/main/shared/services/utility.service';
import { Component, NgZone, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { MsalBroadcastService, MsalService } from '@azure/msal-angular';
import { Subject, filter, takeUntil } from 'rxjs';
import { AccountInfo, InteractionStatus } from '@azure/msal-browser';
import { SdrlSecurityService } from 'src/app/main/agt-portal/services/sdrl-security.service';
import { ListenDbService } from 'src/app/main/listen-db/services/listen-db.service';
import { ListeUserDetails } from 'src/app/main/listen-db/model/listen-db.model';
import { IResponse, ISearchDto } from 'src/app/main/shared/model/shared.model';
import { LdaService } from 'src/app/main/shared/services/lda.service';

@Component({
  selector: 'app-callback',
  templateUrl: './callback.component.html',
  styleUrls: ['./callback.component.css'],
})

export class CallbackComponent implements OnInit {
  isIframe = false;
  loginDisplay = false;
  loggedUser: string = '';
  hello: string = '';

  test: string = '';

  public status: string = '';
  protected ngUnsubscribe: Subject<void> = new Subject<void>();
  constructor(
    //private oktaService: OktaService,
    private router: Router,
    private _ngZone: NgZone,
    private authorizationService: AuthorizationService,
    private utilityService: UtilityService,
    private msalService: MsalService,
    private msalBroadcastService: MsalBroadcastService,
    private sdrlSecurityService: SdrlSecurityService,
    private listenDbService: ListenDbService,
    private ldaService: LdaService
  ) {
  }

  ngOnInit() {

    this.msalBroadcastService.inProgress$
      .pipe(filter((status) => status === InteractionStatus.None))
      .subscribe(() => {
        this.checkAzureADAccount();
      });
    //this.login();
  }

  checkAzureADAccount() {
    this.msalService.handleRedirectObservable().subscribe((response: any) => {
      if (response && response.account) {

        let account: AccountInfo = response.account;
        sessionStorage.setItem('aad_cred', JSON.stringify(account));
        this.loggedUser = account.name ? account.name : '';
        sessionStorage.setItem('token', response.accessToken);

        if (account && account.idTokenClaims) {
          let edaUser!: EDAUser;
          let userInfo: any = { email: '', firstName: '', lastName: '', fullName: '' };
          userInfo.firstName = account.idTokenClaims!.given_name;//account.idTokenClaims['given_name'];
          userInfo.lastName = account.idTokenClaims!.family_name;//account.idTokenClaims['family_name'];
          userInfo.email = account.username;//account['email'];
          userInfo.fullName = userInfo.firstName + ' ' + userInfo.lastName;
          sessionStorage.setItem('userinfo', JSON.stringify(userInfo));
          // let defaultApp = 'network-data';
          // this.router.navigate(['/' + defaultApp]);
          //
          this.getHelpFileInfo();
          this.authorizationService.getLoggedinUser().subscribe((data: any) => {
            if (data !== null && data !== '') {
              if (data.documents.length > 0) {
                edaUser = data.documents[0];
                sessionStorage.setItem('loggedinUser', JSON.stringify(edaUser));
                //AS PER RAO, Clearing the items on opening the application
                localStorage.removeItem('PrintBasket');

                let defaultApp = edaUser.DEFAULTAPP!.toLocaleLowerCase();
                if (defaultApp! && defaultApp.includes("network data")) {
                  defaultApp = 'network-data';
                }

                if (edaUser.HASAGTPORTALACCESS_ORIG_TYPE === true) {
                  this.sdrlSecurityService.getPortalUserbyEmail(edaUser.EMAILID!);
                }
                this.utilityService.addUserModuleLog(defaultApp, this.utilityService.getSubmodule(defaultApp));
                if (edaUser.DEFAULTAPP === 'Listen-DB') {
                  this.utilityService.mapToJiraComponent("Listen DB");
                  this.getListenDBUserRoleAndRedirectURL(edaUser, defaultApp, true);
                }
                else {
                  if (edaUser.HASLISTENDBACCESS_ORIG_TYPE == true) {
                    this.getListenDBUserRoleAndRedirectURL(edaUser, defaultApp, false);
                  }
                  this.router.navigate(['/' + defaultApp]);
                  this._ngZone.run(() => {
                    //This delay purposefully given to load sdrl Roles in AGT, and this delay hits only for AGT users.
                    setTimeout((test: any) => { this.router.navigate(['/' + defaultApp]) }, edaUser.HASAGTPORTALACCESS_ORIG_TYPE === true ? 1000 : 0);
                  });
                }
              }
              else if (data.documents.length === 0) {
                this._ngZone.run(() => {
                  this.router.navigate(['/misc/unauthorized']);
                });
              }
            }

            //return null;
          },
            (error: any) => {
              console.log('error ', error);
              return null;
            });
        }

      } else {
        this.msalService.loginRedirect();
      }
    });
  }

  login() {
    this.msalService.loginRedirect();
  }

  getHelpFileInfo() {
    let filterDetails: ISearchDto = {
    };
    this.ldaService.getFilteredData('helpfiles_appdata', 'authorization', filterDetails, true).pipe(takeUntil(this.ngUnsubscribe)).subscribe((res: IResponse) => {

      if (res) {
        if (res.documents) {
          sessionStorage.setItem('helpFiles', JSON.stringify(res.documents));
        }
      }
    });
  }



  async getListenDBUserRoleAndRedirectURL(edaUser: any, defaultApp: string, isNavigationRequired: boolean) {
    if (edaUser.HASLISTENDBACCESS_ORIG_TYPE === true) {

      await this.listenDbService.GetUserRoles(edaUser.EMAILID!).pipe(takeUntil(this.ngUnsubscribe))
        .subscribe((res: any) => {
          let listeUserDetails = new ListeUserDetails();
          if (typeof res != 'undefined' && res.outputParamValue === true) {
            listeUserDetails.firstName = res.records[0].vorname;
            listeUserDetails.lastName = res.records[0].nachname;
            listeUserDetails.zid = res.records[0].gid;
            listeUserDetails.isreadwriteuser = res.records[0].isreadwriteuser === 1 ? true : false;
            listeUserDetails.standort = res.records[0].standort;
            listeUserDetails.personaL_NR = res.records[0].personaL_NR;
          }
          sessionStorage.setItem('listenDBUser', JSON.stringify(listeUserDetails));
          if (isNavigationRequired)
            this.router.navigate(['/' + defaultApp]);
        });
    }
  }
}
