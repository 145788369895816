import { AuthorizationService } from './authorization.service';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { CanActivate, Router } from '@angular/router';
import { MsalService } from '@azure/msal-angular';
import { environment } from 'src/environments/environment';


@Injectable({
  providedIn: 'root'
})
export class AuthGuardService implements CanActivate {
  navigationStart = '';
  inMaintenance: boolean = false;
  userInfo: any;
  constructor(
    private msalService: MsalService,
    private authorizationService: AuthorizationService,
    private router: Router
  ) { }

  canActivate(): Observable<boolean> {

    const identifiedUser = sessionStorage.getItem('aad_cred');
    const user = this.msalService.instance.getAllAccounts()[0];
    //const backEndScope: string[] = backEndScope;  // ["api://5af1159a-7066-4d92-b56c-48ab654592e1/User.Read"];
    ;
    const requester = {
      scopes: environment.backEndScope,
      account: user as any
    };
    // console.info('canActivate')

    return new Observable<boolean>((observer) => {
      this.msalService.acquireTokenSilent(requester).subscribe(
        token => {

          if (this.inMaintenance) {
            this.router.navigate(['/maintenance-page']);
            observer.next(false);
          }
          else {
            this.authorizationService.isAuthorizedUser();

            this.userInfo = JSON.parse(sessionStorage.getItem('userinfo') || '{}');

            if (Object.keys(this.userInfo).length === 0) {
              let userInfo: any = { email: '', firstName: '', lastName: '', fullName: '' };
              userInfo.firstName = user.idTokenClaims!.given_name;//account.idTokenClaims['given_name'];
              userInfo.lastName = user.idTokenClaims!.family_name;//account.idTokenClaims['family_name'];
              userInfo.email = user.username;//account['email'];
              userInfo.fullName = userInfo.firstName + ' ' + userInfo.lastName;
              sessionStorage.setItem('userinfo', JSON.stringify(userInfo));
              this.authorizationService.addUserLogIfUserAccessingUsingUrl();
            }


            observer.next(true);
          }
          observer.complete();
        },
        e => {
          console.log(e);
          observer.next(false);
          observer.complete();
        }
      );

    })
  }
}
