import { MysqlService } from 'src/app/main/shared/services/mysql.service';
import { ISDRLPortalUser } from './../model/agt-portal-sdrl.model';
import { Injectable } from '@angular/core';
import { SDRLRole } from '../model/agt-portal-sdrl.model';
import { CommandType, IRelationalDataDto, ParameterDirection, RelationalDatabases } from '../../shared/model/shared.model';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class SdrlSecurityService {
  protected ngUnsubscribe: Subject<void> = new Subject<void>();
  constructor(private mysqlService: MysqlService) { }


  ConvertStringToRole(strRole: string): SDRLRole {
    switch (strRole.trim()) {
      case "Guest":
        return SDRLRole.Guest;
      case "Coordinator":
        return SDRLRole.Coordinator;
      case "ProjectManager":
        return SDRLRole.ProjectManager;
      case "Administrator":
        return SDRLRole.Administrator;
      default:
        return SDRLRole.Guest;
    }
  }

  GetUserRoles(sdrlPortalUser: ISDRLPortalUser): ISDRLPortalUser {

    let headerFilteredOptions: IRelationalDataDto = {
      filters: new Array(),
      sorts: new Array(),
      name: '',
      commandType: CommandType.StoredProcedure,
      parameters: [],
      databasename: ''
    };

    headerFilteredOptions.databasename = RelationalDatabases.agt_sdrl.toString();
    headerFilteredOptions.name = 'GetRolesByUser';
    headerFilteredOptions.commandType = CommandType.StoredProcedure;
    headerFilteredOptions.parameters = [{ name: 'p_UserName', value: sdrlPortalUser.userName, parameterDirection: ParameterDirection.Input }];
    headerFilteredOptions.filters = [];
    headerFilteredOptions.sorts = [];
    sdrlPortalUser.roles = [];
    this.mysqlService.getFilteredRDSData(headerFilteredOptions)
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((res: any) => {
        let wholeResponse: any = res;
        for (let i = 0; i < wholeResponse.length; i++) {
          sdrlPortalUser.roles.push(wholeResponse[i].roleID as SDRLRole);
        }

        sessionStorage.setItem('sdrlPortalUser', JSON.stringify(sdrlPortalUser));
      });

    return sdrlPortalUser;
  }

  getPortalUserbyEmail(email: string) {
    let headerFilteredOptions: IRelationalDataDto = {
      filters: new Array(),
      sorts: new Array(),
      name: '',
      commandType: CommandType.StoredProcedure,
      parameters: [],
      databasename: ''
    };

    headerFilteredOptions.databasename = RelationalDatabases.agt_parts.toString();
    headerFilteredOptions.name = 'GetSingleSharepoint_UserList_ByEmail';
    headerFilteredOptions.commandType = CommandType.StoredProcedure;
    headerFilteredOptions.parameters = [{ name: 'p_Email', value: email, parameterDirection: ParameterDirection.Input }];
    headerFilteredOptions.filters = [];
    headerFilteredOptions.sorts = [];


    this.mysqlService.getFilteredRDSData(headerFilteredOptions).pipe(takeUntil(this.ngUnsubscribe)).subscribe((res: any) => {
        let wholeResponse: any = res;
        if(wholeResponse){

        let sdrlPortalUser: ISDRLPortalUser = {} as ISDRLPortalUser;
        sdrlPortalUser.userName = wholeResponse[0].userName;
        sdrlPortalUser.fullName = wholeResponse[0].preferredName;
        sdrlPortalUser.email = wholeResponse[0].workEmail;
        sdrlPortalUser.phone = wholeResponse[0].workPhone;
        sdrlPortalUser.department = wholeResponse[0].department;
        sdrlPortalUser.title = wholeResponse[0].title;
        sdrlPortalUser.office = wholeResponse[0].office;

        if (sdrlPortalUser.fullName.trim() == "") {
          let i: number = sdrlPortalUser.userName.indexOf('\\');
          if (i >= 0)
            sdrlPortalUser.fullName = sdrlPortalUser.userName.substring(i + 1);
          else
            sdrlPortalUser.fullName = sdrlPortalUser.userName;
        }

        this.GetUserRoles(sdrlPortalUser);
      }
      });
  }


}

