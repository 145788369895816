import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { ReactiveFormsModule } from '@angular/forms';
// prime Ng
import { TableModule } from 'primeng/table';
import { CalendarModule } from 'primeng/calendar';
import { SliderModule } from 'primeng/slider';
import { MultiSelectModule } from 'primeng/multiselect';
import { ContextMenuModule } from 'primeng/contextmenu';
import { DialogModule } from 'primeng/dialog';
import { ButtonModule } from 'primeng/button';
import { DropdownModule } from 'primeng/dropdown';
import { ProgressBarModule } from 'primeng/progressbar';
import { InputTextModule } from 'primeng/inputtext';
import { DividerModule } from 'primeng/divider';
import { PaginatorModule } from 'primeng/paginator'; // Here
import { ToggleButtonModule } from 'primeng/togglebutton';
import { TabViewModule } from 'primeng/tabview';
import { PanelModule } from 'primeng/panel';
import { CardModule } from 'primeng/card';
import { RippleModule } from 'primeng/ripple';
import { MenuModule } from 'primeng/menu';
import { SidebarModule } from 'primeng/sidebar';
import { ConfirmationService, MessageService } from 'primeng/api';
import { ToolbarModule } from 'primeng/toolbar';
import { ConfirmDialogModule } from 'primeng/confirmdialog';
import { CheckboxModule } from 'primeng/checkbox';
import { BlockUIModule } from 'primeng/blockui';
import { TreeTableModule } from 'primeng/treetable';
import { ProgressSpinnerModule } from 'primeng/progressspinner';
import { PanelMenuModule } from 'primeng/panelmenu';
import { BadgeModule } from "primeng/badge";
import { OverlayPanelModule } from 'primeng/overlaypanel';
import { ListboxModule } from 'primeng/listbox';
import { RadioButtonModule } from 'primeng/radiobutton';
import { ScrollPanelModule } from 'primeng/scrollpanel';
import { TreeModule } from 'primeng/tree'
import { ToastModule } from 'primeng/toast';
import { SplitterModule } from 'primeng/splitter';
import { DialogService, DynamicDialogModule } from 'primeng/dynamicdialog';
import { SelectButtonModule } from 'primeng/selectbutton';


//shared components

@NgModule({
  imports: [
    OverlayPanelModule,
    CommonModule,
    RouterModule,
    ReactiveFormsModule,
    TableModule,
    CalendarModule,
    SliderModule,
    DialogModule,
    MultiSelectModule,
    ContextMenuModule,
    DropdownModule,
    ButtonModule,
    InputTextModule,
    ProgressBarModule,
    DividerModule,
    PaginatorModule,
    ToggleButtonModule,
    CardModule,
    PanelModule,
    MenuModule,
    RippleModule,
    SidebarModule,
    BlockUIModule,
    ToolbarModule,
    ConfirmDialogModule,
    CheckboxModule,
    TableModule,
    ButtonModule,
    ToastModule,
    TreeTableModule,
    ProgressSpinnerModule,
    TabViewModule,
    PanelMenuModule,
    BadgeModule,
    RadioButtonModule,
    ListboxModule,
    ScrollPanelModule,
    TreeModule,
    SplitterModule,
    DynamicDialogModule,
    SelectButtonModule

  ],
  exports: [
    OverlayPanelModule,
    RouterModule,
    ReactiveFormsModule,
    TableModule,
    CalendarModule,
    SliderModule,
    DialogModule,
    MultiSelectModule,
    ContextMenuModule,
    DropdownModule,
    ButtonModule,
    ToastModule,
    InputTextModule,
    ProgressBarModule,
    DividerModule,
    PaginatorModule,
    ToggleButtonModule,
    CardModule,
    PanelModule,
    MenuModule,
    RippleModule,
    SidebarModule,
    ToolbarModule,
    ConfirmDialogModule,
    CheckboxModule,
    TableModule,
    ButtonModule,
    BlockUIModule,
    TreeTableModule,
    ProgressSpinnerModule,
    TabViewModule,
    PanelMenuModule,
    BadgeModule,
    RadioButtonModule,
    ListboxModule,
    ScrollPanelModule,
    TreeModule,
    SplitterModule,
    DynamicDialogModule,
    SelectButtonModule
  ],
  providers: [MessageService,DialogService,ConfirmationService],
})
export class primeNgModule { }
