
import { Component, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { MenuItem, PrimeIcons } from 'primeng/api';
import { environment } from 'src/environments/environment';
import { ContextMenuComponent } from '../context-menu/context-menu.component'
import { EDAUser, UserInfo } from 'src/app/main/admin/models/admin.model';
import { UtilityService } from '../../services/utility.service';
import { ISDRLPortalUser, SDRLRole } from 'src/app/main/agt-portal/model/agt-portal-sdrl.model';
import { SdrlSecurityService } from 'src/app/main/agt-portal/services/sdrl-security.service';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit {


  @ViewChild("navigationMenu", { static: false }) navigationMenu!: any;
  @ViewChild(ContextMenuComponent, { static: false }) contextMenu!: ContextMenuComponent;
  title = '';
  //this is a change
  //DD/month/YYYY

  headerLabel: string = environment.Header_Label;
  helpDocumentPath: boolean = false;
  helpFileName: string = '';

  items!: MenuItem[];
  edaUser!: EDAUser;
  userinfo!: UserInfo;
  display: boolean = false;
  isMenuVisible: boolean = false;
  showContextMenu: boolean = false;
  xAxis: number = 15;
  yAxis: number = 15;
  newTabLink: string = '';
  defaultEESArchiveExpanded: boolean = false;
  defaultIFSExpanded: boolean = false;
  defaultONCEExpanded: boolean = false;
  defaultAGTPortalExpanded: boolean = false;
  defaultListenDBExpanded: boolean = false;
  defaultModmanExpanded: boolean = false;
  userNavigatedModule!: string;
  portalUser!: ISDRLPortalUser;
  isSDRLVisible: boolean = false;
  isSDRLDocControlVisbile: boolean = true;
  isSDRLProjectManagementVisbile: boolean = true;
  isSDRLAdminVisbile: boolean = true;
  selectedModule: string = '';
  defaultIDMSExpanded: boolean = false;
  defaultNAMCSSExpanded: boolean = false;
  defaultIMSExpanded: boolean = false;

  agtPortalSubModulesNavigation = {
    isCertificationExpanded: false,
    isChangeManagementExpanded: false,
    isDocregisterExpanded: false,
    isItemRequestsExpanded: false,
    isSDRLExpanded: false,
    isSDRLDocControlExpanded: false,
    isSDRLConfigControlExpanded: false,
    isSDRLAdminExpanded: false,
    isSDRLReportExpanded: false
  }

  eesArchiveSubModulesNavigation = {
    isWorkOrdersExpanded: false,
    isBOMExpanded: false,
    isPartsOrdersExpanded: false,
    isItemMasterReportsExpanded: false,
  }

  idmsSubModulesNavigation = {
    isItemMasterReportsExpanded: false,
    isBOMExpanded: false,
    isEngineeringChangeExpanded: false,
    isPurchasingExpanded: false,
    isMiscItemReportsExpanded: false,
    isToolingExpanded: false,
    isRoutingReportsExpanded: false,
    isPatternAPPages: false,
    isShopOrders: false,
    isCustomerComplaint: false
  }

  namCSSNavigation = {
    isCCSReportsExpanded: false,
  };

  imsNavigation = {
    isDISExpanded: false,
  };

  routingLinks = {
    "Adept": "/adept",
    "Parts": "/once/main-table",
    "Projects": "/once/tag-table",
    "Project Info": "/ifs/projectinfo",
    "Project BOM": "/ifs/projectbom",
    "Documents": "/ifs/documents",
    "Parts ": "/ifs/parts",
    "Purchase Orders": "/ifs/purchaseorders",
    "Work Orders": "/ifs/workorders",
    "Customer Orders": "/ifs/customerorders",
    "Country Of Origin": "/ifs/country-of-origin",
    "Bill Of Materials": "/ifs/billofmaterials",

    "Edit Users": "/admin",
  }
  constructor(private router: Router, private utilityService: UtilityService, private sdrlSecurityService: SdrlSecurityService) {

  }

  ngOnInit(): void {
    this.title = this.getTitle();
    this.validateHelpFile(this.title);
    this.edaUser = JSON.parse(this.utilityService.getDecryptedLoggedinUserDetails() || '{}');
    this.userinfo = JSON.parse(sessionStorage.getItem('userinfo') || '{}');

    this.defaultAGTPortalExpanded;
    this.agtPortalSubModulesNavigation = JSON.parse(sessionStorage.getItem('agtPortalSubModulesNavigation') || '{}');
    this.eesArchiveSubModulesNavigation = JSON.parse(sessionStorage.getItem('eesArchiveSubModulesNavigation') || '{}');
    this.imsNavigation = JSON.parse(sessionStorage.getItem('imsNavigation') || '{}');
    this.namCSSNavigation = JSON.parse(sessionStorage.getItem('namCSSNavigation') || '{}');
    this.idmsSubModulesNavigation = JSON.parse(sessionStorage.getItem('idmsSubModulesNavigation') || '{}');

    // this.IsJiraDashboardAvailable = JSON.parse(sessionStorage.getItem('IsJiraDashboardAvailable') || '{}');


    //this.items = this.getItems();
    this.isMenuVisible = true;
    this.sessionStorageValid();

  }

  showJiraLink() {
    window.open(environment.Jira_URL + '&SelectedComp=' + sessionStorage.getItem('jiraSelectedModule'));
  }

  getItems(): MenuItem[] {

    const items = [
      // Adept
      {
        label: 'Adept', visible: this.edaUser.HASADEPTACCESS_ORIG_TYPE, icon: PrimeIcons.TABLE, command: () => { this.navigateByUrl('/adept', 'Adept', 'Adept Data'); }
      },
      // ONCe
      {
        label: 'ONCE', visible: this.edaUser.HASONCEACCESS_ORIG_TYPE, expanded: this.defaultONCEExpanded, command: () => this.onModuleClick('ONCE'),
        items: [
          { label: 'Parts', icon: 'c-icons custom-part-icon', command: () => { this.navigateByUrl('/once/main-table', 'ONCE', 'Parts'); } },
          { label: 'Projects', icon: PrimeIcons.TAGS, command: () => { this.navigateByUrl('/once/tag-table', 'ONCE', 'Projects'); } }
        ]
      },
      // IFS
      {
        label: 'IFS', visible: this.edaUser.HASIFSACCESS_ORIG_TYPE, expanded: this.defaultIFSExpanded, command: () => this.onModuleClick('IFS'),
        items: [
          { label: 'Parts', icon: 'c-icons ifs-parts-icon', command: () => { this.navigateByUrl('/ifs/parts', 'IFS', 'Parts') } },
          { label: 'Documents', icon: 'c-icons ifs-documents-icon', command: () => { this.navigateByUrl('/ifs/documents', 'IFS', 'Documents') } },
          { label: 'Project Info', icon: 'c-icons ifs-project-info-icon', command: () => { this.navigateByUrl('/ifs/projectinfo', 'IFS', 'Project Info'); } },
          { label: 'Project BOM', icon: 'c-icons ifs-bom-icon', command: () => { this.navigateByUrl('/ifs/projectbom', 'IFS', 'Project BOM'); } },
          { label: 'Bill Of Materials', icon: 'c-icons ifs-bom-of-materials-icon', command: () => { this.navigateByUrl('/ifs/billofmaterials', 'IFS', 'Bill Of Materials'); } },
          { label: 'Purchase Orders', icon: 'c-icons ifs-purchase-orders-icon', command: () => { this.navigateByUrl('/ifs/purchaseorders', 'IFS', 'Purchase Orders'); } },
          { label: 'Work Orders', icon: 'c-icons ifs-work-orders-icon', command: () => { this.navigateByUrl('/ifs/workorders', 'IFS', 'Work Orders'); } },
          { label: 'Customer Orders', icon: 'c-icons ifs-customer-orders-icon', command: () => { this.navigateByUrl('/ifs/customerorders', 'IFS', 'Customer Orders'); } },
          { label: 'Country Of Origin', icon: 'c-icons ifs-country-of-origin-icon', command: () => { this.navigateByUrl('/ifs/country-of-origin', 'IFS', 'Country Of Origin'); } },
        ]
      },
      // NetWorkData - Header
      {
        label: 'Network Data', visible: (
          (this.edaUser.hasOwnProperty("HASNETWORKDATACANADAACCESS_ORIG_TYPE") && this.edaUser.HASNETWORKDATACANADAACCESS_ORIG_TYPE)
          || (this.edaUser.hasOwnProperty("HASNETWORKDATAUSAACCESS_ORIG_TYPE") && this.edaUser.HASNETWORKDATAUSAACCESS_ORIG_TYPE)
          || (this.edaUser.hasOwnProperty("HASNETWORKDATAFRANCEACCESS_ORIG_TYPE") && this.edaUser.HASNETWORKDATAFRANCEACCESS_ORIG_TYPE)
          || (this.edaUser.hasOwnProperty("HASNETWORKDATAGERMANYACCESS_ORIG_TYPE") && this.edaUser.HASNETWORKDATAGERMANYACCESS_ORIG_TYPE)
          || (this.edaUser.hasOwnProperty("HASNETWORKDATANORWAYACCESS_ORIG_TYPE") && this.edaUser.HASNETWORKDATANORWAYACCESS_ORIG_TYPE)),
        icon: PrimeIcons.FOLDER_OPEN, command: () => { this.navigateByUrl('/network-data', 'Network Data', 'Network Data'); }
      },

      // Teamcenter Archive
      {
        label: 'Teamcenter Archive', visible: (this.edaUser.hasOwnProperty("HASTEAMCENTERARCHIVEACCESS_ORIG_TYPE") && this.edaUser.HASTEAMCENTERARCHIVEACCESS_ORIG_TYPE), icon: PrimeIcons.FOLDER_OPEN,
        command: () => { this.navigateByUrl('/teamcenter-archive', 'Teamcenter Archive', 'TeamcenterArchive'); }
      },

      // Transformer Cards
      {
        label: 'Transformer Cards', visible: (this.edaUser.hasOwnProperty("HASTRANSFORMERCARDSACCESS_ORIG_TYPE") && this.edaUser.HASTRANSFORMERCARDSACCESS_ORIG_TYPE), icon: PrimeIcons.FOLDER_OPEN,
        command: () => { this.navigateByUrl('/transformer-cards', 'Transformer Cards', 'Transformer Cards'); }
      },
      // CTS Drawings
      {
        label: 'CTS Drawings', visible: (this.edaUser.hasOwnProperty("HASCTSDRAWINGSACCESS_ORIG_TYPE") && this.edaUser.HASCTSDRAWINGSACCESS_ORIG_TYPE), icon: PrimeIcons.FOLDER_OPEN,
        command: () => { this.navigateByUrl('/cts-drawings', 'CTS Drawings', 'CTS Drawings'); }
      },
      // PPT Drawings
      {
        label: 'PNP Drawings', visible: (this.edaUser.hasOwnProperty("HASPPTDRAWINGSACCESS_ORIG_TYPE") && this.edaUser.HASPPTDRAWINGSACCESS_ORIG_TYPE), icon: PrimeIcons.FOLDER_OPEN,
        command: () => { this.navigateByUrl('/ppt-drawings', 'PNP Drawings', 'PNP Drawings'); }
      },

      // Listen DB
      {
        //(this.edaUser.hasOwnProperty("HASLISTENDBACCESS_ORIG_TYPE") && this.edaUser.HASLISTENDBACCESS_ORIG_TYPE)
        label: 'Listen DB', visible: (this.edaUser.hasOwnProperty("HASLISTENDBACCESS_ORIG_TYPE") && this.edaUser.HASLISTENDBACCESS_ORIG_TYPE), expanded: this.defaultListenDBExpanded,
        command: () => this.onModuleClick('Listen DB'),
        items: [
          {
            label: 'Master Projects', icon: 'c-icons ldb-master-projects', command: () => { this.navigateByUrl('/listen-db/master-projects', 'Listen DB', 'Master Projects'); }
          },
          { label: 'Project KKS / AKZ Overview', icon: 'c-icons ldb-kks-akz-overview', command: () => { this.navigateByUrl('/listen-db/SPPID', 'Listen DB', 'Project KKS / AKZ Overview') } },
          {
            label: 'Standard KKS Lists', icon: 'c-icons ldb-kks-list', command: () => { this.navigateByUrl('/listen-db/single-kks', 'Listen DB', 'Standard KKS Lists'); }
          },
          {
            label: 'Project KKS / AKZ Text', icon: 'c-icons ldb-kks-text', command: () => { this.navigateByUrl('/listen-db/kks-list', 'Listen DB', 'Project KKS / AKZ Text'); }
          },
          {
            label: 'KKS / AKZ Search', icon: 'c-icons ldb-kks-search', command: () => { this.navigateByUrl('/listen-db/search-all-kks', 'Listen DB', 'KKS / AKZ Search'); }
          },
          { label: 'MEL', icon: 'c-icons ldb-mel-icon', command: () => { this.navigateByUrl('/listen-db/mel-list', 'Listen DB', 'MEL') } },
          { label: 'MEL Comparision', icon: 'c-icons ldb-mel-comparison', command: () => { this.navigateByUrl('/listen-db/mel-index-comparison', 'Listen DB', 'MEL Comparision') } },
          { label: 'VEL', icon: 'c-icons ldb-vel-icon', command: () => { this.navigateByUrl('/listen-db/vel', 'Listen DB', 'VEL') } },
          { label: 'VEL Comparision', icon: 'c-icons ldb-vel-comparison-icon', command: () => { this.navigateByUrl('/listen-db/vel-comparison', 'Listen DB', 'VEL Comparision') } },
          {
            label: 'List Of Values', icon: 'c-icons ldb-list-of-values-icon', command: () => { this.navigateByUrl('/listen-db/list-of-values', 'Listen DB', 'MEL List Of Values') }
          },
          {
            label: 'MEL/VEL - Copy Plant', icon: 'c-icons ldb-melvel-copy-plant-icon', command: () => { this.navigateByUrl('/listen-db/mel-copy', 'Listen DB', 'MEL/VEL - KKS Data Copy'); }
          },
          {
            label: 'MEL/VEL Copy Plant KKS / AKZ', icon: 'c-icons ldb-melvel-copy-plant-kks-akz-icon', command: () => { this.navigateByUrl('/listen-db/copy-plant-kks', 'Listen DB', 'MEL/VEL Copy Plant KKS / AKZ'); }
          },
          {
            label: 'Copy Project KKS / AKZ Text', icon: 'c-icons ldb-project-kks-text-icon', command: () => { this.navigateByUrl('/listen-db/copy-kks-text', 'Listen DB', 'Copy Project KKS / AKZ Text'); }
          },
        ]
      },

      // Modman
      {
        label: 'Modman', visible: (this.edaUser.hasOwnProperty("HASMODMANACCESS_ORIG_TYPE") && this.edaUser.HASMODMANACCESS_ORIG_TYPE), expanded: this.defaultModmanExpanded,
        command: () => this.onModuleClick('Modman'),
        items: [
          {
            label: 'Projects', icon: 'c-icons modman-projects-icon', command: () => { this.navigateByUrl('/modman/main', 'Modman', 'Projects'); }
          },
          {
            label: 'Modules', icon: 'c-icons modman-modules-icon', command: () => { this.navigateByUrl('/modman/modules-main', 'Modman', 'Modules'); }
          },
          {
            label: 'Module List', icon: 'c-icons modman-module-list-icon', command: () => { this.navigateByUrl('/modman/module-list-main', 'Modman', 'Module-List'); }
          },
          {
            label: 'Users', icon: 'c-icons modman-users-icon', command: () => { this.navigateByUrl('/modman/users-main', 'Modman', 'Users'); }
          },
        ]
      },

      // AGT Portal
      {
        label: 'AGT Portal',
        visible: ((this.edaUser.hasOwnProperty("HASAGTPORTALACCESS_ORIG_TYPE") && this.edaUser.HASAGTPORTALACCESS_ORIG_TYPE)),
        expanded: this.defaultAGTPortalExpanded,
        command: () => this.onModuleClick('AGT Portal'),

        items: [
          {
            label: 'Certification',
            expanded: this.agtPortalSubModulesNavigation.isCertificationExpanded,

            command: () => { this.onSubModuleExpand("Certification"); },

            items: [
              {
                label: ((this.edaUser.hasOwnProperty("HASAGTCERTWRITEACCESS_ORIG_TYPE") && this.edaUser.HASAGTCERTWRITEACCESS_ORIG_TYPE)) === true ? 'Search / Edit' : 'Search',
                icon: 'c-icons search-certification-icon',
                command: () => { this.navigateByUrl('/agt-portal/certification-search', 'AGT Portal', 'Certification Search') }
              },
              {
                visible: ((this.edaUser.hasOwnProperty("HASAGTCERTWRITEACCESS_ORIG_TYPE") && this.edaUser.HASAGTCERTWRITEACCESS_ORIG_TYPE)),
                label: 'Add Cert', icon: 'c-icons add-cert-icon',
                command: () => { this.navigateByUrl('/agt-portal/certification-edit', 'AGT Portal', 'Certification Edit') }
              },
              {
                visible: false, label: 'Edit Title', icon: PrimeIcons.COPY,
                command: () => { this.navigateByUrl('/agt-portal/certification-title', 'AGT Portal', 'Certification Title') }
              },
            ]
          },
          {
            label: 'Change Management', expanded: this.agtPortalSubModulesNavigation.isChangeManagementExpanded,
            command: () => { this.onSubModuleExpand("Change Management"); },
            items: [
              {
                label: 'Quick Find / Search', icon: 'c-icons quick-find-icon',
                command: () => { this.navigateByUrl('/agt-portal/change-management', 'AGT Portal', 'Quick Find / Search') }
              },
              {
                label: 'Reports', icon: 'c-icons reports-icon',
                command: () => { this.navigateByUrl('/agt-portal/change-management-reports', 'AGT Portal', 'Reports') }
              },
            ]
          },
          {
            label: 'Document Register',
            expanded: this.agtPortalSubModulesNavigation.isDocregisterExpanded,
            command: () => { this.onSubModuleExpand("Document Register"); },

            items: [
              { label: 'Document Search', icon: 'c-icons document-search-icon', command: () => { this.navigateByUrl('/agt-portal/document-register', 'AGT Portal', 'Document Search') } },
              { label: 'Legacy Drawing Release Notices', icon: 'c-icons legacy-drawing-release-note-icon', command: () => { this.navigateByUrl('/agt-portal/drawing-release-notices', 'AGT Portal', 'Legacy Drawing Release Notices'); } },
            ]
          },

          { label: 'Find View Print', icon: 'c-icons find-view-print-icon', command: () => { this.navigateByUrl('/agt-portal/find-view-print', 'AGT Portal', 'Find View Print'); } },
          { label: 'Print Basket', icon: 'c-icons print-basket-menu-icon', command: () => { this.navigateByUrl('/agt-portal/print-basket', 'AGT Portal', 'Print Basket'); } },
          {
            label: 'Item Requests', expanded: this.agtPortalSubModulesNavigation.isItemRequestsExpanded, command: () => { this.onSubModuleExpand("Item Requests"); },
            items: [
              { label: 'Data Processing / GED', icon: 'c-icons data-processing-icon', command: () => { this.navigateByUrl('/agt-portal/item-request', 'AGT Portal', 'Request') } },
              { label: 'Project Status', icon: 'c-icons project-status-icon', command: () => { this.navigateByUrl('/agt-portal/item-request-project-status', 'AGT Portal', 'Project Status'); } },
            ]
          },
          { label: 'Parts Catalog', icon: 'c-icons custom-part-icon', command: () => { this.navigateByUrl('/agt-portal/parts-catalog', 'AGT Portal', 'Parts Catalog'); } },
          { label: 'Parts CICS Legacy', icon: 'c-icons parts-legacy-icon', command: () => { this.navigateByUrl('/agt-portal/parts-cics-legacy-sap', 'AGT Portal', 'Parts CICS Legacy'); } },
          { label: 'Pipespool Verification', icon: 'c-icons pipe-spool-icon', command: () => { this.navigateByUrl('/agt-portal/pipespool-verification', 'AGT Portal', 'Pipespool Verification'); } },
          {
            label: 'SDRL', visible: this.isSDRLVisible, expanded: this.agtPortalSubModulesNavigation.isSDRLExpanded, command: () => { this.onSubModuleExpand("SDRL"); },
            items: [
              { label: 'Quick Find / Search', icon: 'c-icons sdrl-quick-find-icon', command: () => { this.navigateByUrl('/agt-portal/sdrl-main', 'AGT Portal - SDRL', 'Quick Find / Search') } },
              { label: 'Projects ', icon: 'c-icons sdrl-projects-icon', command: () => { this.navigateByUrl('/agt-portal/sdrl-project-list', 'AGT Portal - SDRL', 'Projects') } },
              { label: 'Reports', icon: 'c-icons data-processing-icon', command: () => { this.navigateByUrl('/agt-portal/project-dashboard', 'AGT Portal - Reports', 'Reports') } },
              { label: 'Masters (Baselines)', icon: 'c-icons sdrl-master-baselines-icon', command: () => { this.navigateByUrl('/agt-portal/sdrl-masters-baseline', 'AGT Portal - SDRL', 'Masters (Baselines)') } },

              {
                label: 'Document Control', visible: this.isSDRLDocControlVisbile, expanded: this.agtPortalSubModulesNavigation.isSDRLDocControlExpanded, command: () => { this.onSubModuleExpand("Document Control"); },
                items: [
                  { label: 'Manage Submissions', icon: 'c-icons sdrl-manage-submissions-icon', command: () => { this.navigateByUrl('/agt-portal/manage-submissions', 'AGT Portal - SDRL', 'Manage Submissions') } },
                ]
              },
              {
                label: 'Configuration Control', visible: this.isSDRLProjectManagementVisbile, expanded: this.agtPortalSubModulesNavigation.isSDRLConfigControlExpanded, command: () => { this.onSubModuleExpand("Configuration Control"); },
                items: [
                  { label: 'Manage Projects', icon: 'c-icons sdrl-manage-projects-icons', command: () => { this.navigateByUrl('/agt-portal/sdrl-project-list', 'AGT Portal - SDRL', 'Manage Projects') } },
                  { label: 'New Project', icon: 'c-icons sdrl-new-project-icons', command: () => { this.navigateByUrl('/agt-portal/project-details?projectId=0', 'AGT Portal - SDRL', 'New Project') } },
                ]
              },
              {
                label: 'Administration', visible: this.isSDRLAdminVisbile, expanded: this.agtPortalSubModulesNavigation.isSDRLAdminExpanded, command: () => { this.onSubModuleExpand("Administration"); },
                items: [
                  { label: 'Masters (Baselines)', icon: 'c-icons sdrl-master-baselines-icon', command: () => { this.navigateByUrl('/agt-portal/administration/masters-baseline', 'AGT Portal - SDRL', 'Masters (Baselines)') } },
                  { label: 'Document Control', icon: 'c-icons sdrl-document-control-icon', command: () => { this.navigateByUrl('/agt-portal/administration/document-control', 'AGT Portal - SDRL', 'Document Control') } },
                  { label: 'Products', icon: 'c-icons sdrl-products-icon', command: () => { this.navigateByUrl('/agt-portal/administration/products', 'AGT Portal - SDRL', 'Products') } },
                  { label: 'Applications', icon: 'c-icons sdrl-applications-icon', command: () => { this.navigateByUrl('/agt-portal/administration/applications', 'AGT Portal - SDRL', 'Applications') } },
                  { label: 'Major Buys', icon: 'c-icons sdrl-majorbuys-icon', command: () => { this.navigateByUrl('/agt-portal/administration/major-buys', 'AGT Portal - SDRL', 'Major Buys') } },
                  { label: 'Customers', icon: 'c-icons sdrl-customers-icon', command: () => { this.navigateByUrl('/agt-portal/administration/customers', 'AGT Portal - SDRL', 'Customers') } },
                  { label: 'Vendors', icon: 'c-icons sdrl-vendors-icon', command: () => { this.navigateByUrl('/agt-portal/administration/vendors', 'AGT Portal - SDRL', 'Vendors') } },
                  { label: 'ValueStreams', icon: 'c-icons sdrl-value-streams-icon', command: () => { this.navigateByUrl('/agt-portal/administration/value-streams', 'AGT Portal - SDRL', 'ValueStreams') } },
                  { label: 'Security Settings', icon: 'c-icons sdrl-security-settings-icon', command: () => { this.navigateByUrl('/agt-portal/administration/security-settings', 'AGT Portal - SDRL', 'Security Settings') } },
                  { label: 'Project Manager / Lead', icon: 'c-icons data-processing-icon', command: () => { this.navigateByUrl('/agt-portal/administration/project-manager-lead', 'AGT Portal - SDRL', 'Project Manager / Lead') } },
                ]
              },

            ]
          },
        ]
      },

      //Legacy Oracle Apps
      //EES Archive System
      {
        label: 'CICS - EES Archive', title: 'CICS archive for Olean',
        visible: this.edaUser.HASEESARCHIVESYSTEMACCESS_ORIG_TYPE,
        expanded: this.defaultEESArchiveExpanded,
        command: () => this.onModuleClick('CICS - EES Archive'),
        items: [
          {
            label: 'Item Master Reports',
            expanded: this.eesArchiveSubModulesNavigation.isItemMasterReportsExpanded,
            command: () => { this.onSubModuleExpand("Item Master Reports"); },
            items: [
              {
                label: 'Item Master Query', title: 'EES Reference: DC1S, DC06, DC03 and more.', icon: 'c-icons ees-item-master-icon',
                command: () => { this.navigateByUrl('/ees-archive-system/item-master-reports/item-master-query', 'CICS - EES Archive', 'Item Master Query') }
              },
              { label: 'Manufacturing Routing by Item Number', icon: 'c-icons ees-item-manufacturing-routing-icon', title: 'EES Reference: DC16.', command: () => { this.navigateByUrl('/ees-archive-system/item-master-reports/manufacturing-routing-by-itemnumber', 'CICS - EES Archive', 'Manufacturing Routing by Item Number') } },
              { label: 'Part Number Conversion Display', icon: 'c-icons ees-part-number-conversion-display-icon', title: 'EES Reference: DC65.', command: () => { this.navigateByUrl('/ees-archive-system/item-master-reports/partnumber-conversion-display', 'CICS - EES Archive', 'Part Number Conversion Display') } },
              { label: 'Essential Details', icon: 'c-icons ees-item-essential-details-icon', title: 'EES Reference: DC03, DC04.', command: () => { this.navigateByUrl('/ees-archive-system/item-master-reports/itemmaster-essential-details', 'CICS - EES Archive', 'Essential Details') } },
              { label: 'Item Reservations', icon: 'c-icons ees-item-reservation-icon', title: 'EES Reference: DC06, option 5.', command: () => { this.navigateByUrl('/ees-archive-system/item-master-reports/item-reservations', 'CICS - EES Archive', 'Item Reservations') } },
              { label: 'All Fields', icon: 'c-icons ees-all-fields-icon', title: 'EES Reference: -', command: () => { this.navigateByUrl('/ees-archive-system/item-master-reports/itemmaster-all-fields', 'CICS - EES Archive', 'All Fields') } },
              { label: 'Extended Description', icon: 'c-icons ees-extended-description-icon', title: 'EES Reference DC55.', command: () => { this.navigateByUrl('/ees-archive-system/item-master-reports/itemmaster-extended-description', 'CICS - EES Archive', 'Extended Description') } },
              { label: 'Engineering Item Display Having Routing', icon: 'c-icons ees-engineering-item-display-icon', title: 'Similar to DC10 in EES.', command: () => { this.navigateByUrl('/ees-archive-system/item-master-reports/engg-item-display-having-routing', 'CICS - EES Archive', 'Engineering Item Display Having Routing') } },
              { label: 'Locations By Item Number', visible: false, icon: 'c-icons search-certification-icon', title: 'EES Reference: -', command: () => { this.navigateByUrl('/ees-archive-system/item-master-reports/locations-by-itemnumber', 'CICS - EES Archive', 'Locations By Item Number') } },
            ]
          },
          {
            label: 'Bill of Materials', expanded: this.eesArchiveSubModulesNavigation.isBOMExpanded, command: () => { this.onSubModuleExpand("Bill of Materials"); },
            items: [
              { label: 'BOM Single Level', icon: 'c-icons ees-bom-single-level-icon', title: 'This Report is same as DC01 in EES.', command: () => { this.navigateByUrl('/ees-archive-system/bill-of-material/bom-single-level', 'CICS - EES Archive', 'BOM Single Level') } },
              { label: 'BOM Where Used', icon: 'c-icons ees-bom-where-used-icon', title: 'This Report is similar to DC02 in EES.', command: () => { this.navigateByUrl('/ees-archive-system/bill-of-material/bom-where-used', 'CICS - EES Archive', 'BOM Where Used') } },
              { label: 'Multi-Level BOM Inquiry', icon: 'c-icons ees-bom-multi-level-icon', title: 'EES Reference: DC09, DC10.', command: () => { this.navigateByUrl('/ees-archive-system/bill-of-material/multilevel-bom-inquiry', 'CICS - EES Archive', 'Multi-Level BOM Inquiry') } },
              { label: 'Manufacturing Indented Bill to Stock Level', icon: 'c-icons ees-bom-indented-icon', title: 'EES Reference: DC09.', command: () => { this.navigateByUrl('/ees-archive-system/bill-of-material/manufacturing-indented-bill-to-stocklevel', 'CICS - EES Archive', 'Manufacturing Indented Bill to Stock Level') } },
              { label: 'BOM Comments', icon: 'c-icons ees-bom-comments-icon', command: () => { this.navigateByUrl('/ees-archive-system/bill-of-material/bom-comments', 'CICS - EES Archive', 'BOM Comments') } },
            ]
          },
          {
            label: 'Work Orders', expanded: this.eesArchiveSubModulesNavigation.isWorkOrdersExpanded, command: () => { this.onSubModuleExpand("Work Orders"); },
            items: [
              { label: 'By Item Number', icon: 'c-icons ees-wo-by-item-number-icon', title: 'EES Reference: DC06., option 4', command: () => { this.navigateByUrl('/ees-archive-system/workorders/wo-by-item-number', 'CICS - EES Archive', 'By Item Number') } },
              { label: 'Details', icon: 'c-icons ees-wo-details-icon', title: 'This Report is similar to DC4D and DC43 in EES.', command: () => { this.navigateByUrl('/ees-archive-system/workorders/wo-details', 'CICS - EES Archive', 'Details') } },
              { label: 'Material Status', icon: 'c-icons ees-wo-material-status-icon', title: 'This Report is similar to DC08 in EES.', command: () => { this.navigateByUrl('/ees-archive-system/workorders/wo-material-status', 'CICS - EES Archive', 'Material Status') } },
              { label: 'By Contract Reference', icon: 'c-icons ees-wo-contract-reference-icon', title: 'This Report is similar to DC3N in EES.', command: () => { this.navigateByUrl('/ees-archive-system/workorders/wo-by-contract-reference', 'CICS - EES Archive', 'By Contract Reference') } },
            ]
          },
          {
            label: 'Parts Orders (Pre-CSS)', expanded: this.eesArchiveSubModulesNavigation.isPartsOrdersExpanded, command: () => { this.onSubModuleExpand("Parts Orders"); },
            items: [
              { label: 'Order Entry - Header - All Columns', icon: 'c-icons ees-po-order-entry-icon', title: 'EES Reference: -', command: () => { this.navigateByUrl('/ees-archive-system/parts-orders/orderentry-header-allcolumns', 'CICS - EES Archive', 'Order Entry - Header - All Columns') } },
              { label: 'Order Ship and Bill Info', icon: 'c-icons ees-po-shipto-bill-icon', title: 'EES Reference: -', command: () => { this.navigateByUrl('/ees-archive-system/parts-orders/order-shipto-bill', 'CICS - EES Archive', 'Order Ship and Bill Info') } },
              { label: 'Order and Line Comments', icon: 'c-icons ees-po-orderline-comments-icon', title: 'EES Reference: -', command: () => { this.navigateByUrl('/ees-archive-system/parts-orders/order-line-comments', 'CICS - EES Archive', 'Order and Line Comments') } },
              { label: 'Order Line Items', icon: 'c-icons ees-po-order-line-items-icon', title: 'EES Reference: -', command: () => { this.navigateByUrl('/ees-archive-system/parts-orders/order-line-items', 'CICS - EES Archive', 'Order Line Items') } },
              { label: 'Order Inquiry', icon: 'c-icons ees-po-inquiry-icon', title: 'EES Reference: DC92.', command: () => { this.navigateByUrl('/ees-archive-system/parts-orders/order-inquiry', 'CICS - EES Archive', 'Order Inquiry') } },
            ]
          },
        ]
      },

      //Legacy Oracle Apps
      //IDMS Archive System // CICS - PNP Archive
      {
        label: 'CICS - PNP Archive', title: 'CICS archive for Painted Post', visible: this.edaUser.HASIDMSACCESS_ORIG_TYPE,
        expanded: this.defaultIDMSExpanded,
        command: () => this.onModuleClick('CICS - PNP Archive'),
        items: [
          {
            label: 'Item Master Reports',
            expanded: this.idmsSubModulesNavigation.isItemMasterReportsExpanded,
            command: () => { this.onSubModuleExpand("IDMS Item Master Reports"); },
            items: [
              {
                label: 'Item Number LookUp', title: 'IDMS Reference: BMIS', icon: 'c-icons document-search-icon',
                command: () => { this.navigateByUrl('/idms/item-master-reports-main/item-number-lookup', 'CICS - PNP Archive', 'Item Number LookUp') }
              },
              {
                label: 'Item Master Inquiry', icon: 'c-icons ees-po-inquiry-icon',
                title: 'IDMS Reference: XXXX.', command: () => {
                  this.navigateByUrl('/idms/item-master-reports-main/item-master-inquiry',
                    'CICS - PNP Archive', 'Item Master Inquiry')
                }
              },

              {
                label: 'Item Master Engineering Data', title: 'IDMS Reference: BMED', icon: 'c-icons ees-extended-description-icon',
                command: () => { this.navigateByUrl('/idms/item-master-reports-main/item-master-engineering-data', 'CICS - PNP Archive', 'Item Master Engineering Data') }
              },
              {
                label: 'Item Master Planning Data', icon: 'c-icons ees-item-essential-details-icon',
                title: 'IDMS Reference: BMID.', command: () => {
                  this.navigateByUrl('/idms/item-master-reports-main/item-master-planning-data',
                    'CICS - PNP Archive', 'Item Master Planning Data')
                }
              },
              {
                label: 'Item Cost Information', icon: 'c-icons ees-item-master-icon',
                title: 'IDMS Reference: PCIT.', command: () => {
                  this.navigateByUrl('/idms/item-master-reports-main/item-cost-information',
                    'CICS - PNP Archive', 'Item Cost Information')
                }
              },

              {
                label: 'Last Actual Costs', title: 'IDMS Reference: BMLA', icon: 'c-icons ees-item-master-icon',
                command: () => { this.navigateByUrl('/idms/item-master-reports-main/last-actual-costs', 'CICS - PNP Archive', 'Last Actual Cost') }
              },
              {
                label: 'Item Inventory Transaction History Interactive', icon: 'c-icons ees-item-manufacturing-routing-icon',
                title: 'IDMS Reference: IABT.', command: () => {
                  this.navigateByUrl('/idms/item-master-reports-main/item-inventory-transaction-history-interactive',
                    'CICS - PNP Archive', 'Item Inventory Transaction History Interactive')
                }
              },

              {
                label: 'Item Inventory Transaction History', title: 'IDMS Reference: IABT', icon: 'c-icons ees-wo-details-icon',
                command: () => { this.navigateByUrl('/idms/item-master-reports-main/item-inventory-transaction-history', 'CICS - PNP Archive', 'Item Inventory Transaction History') }
              },
              {
                label: 'Item Location Inventory', icon: 'c-icons idms-routing-master-search-icon',
                title: 'IDMS Reference: IA02.', command: () => {
                  this.navigateByUrl('/idms/item-master-reports-main/item-location-inventory',
                    'CICS - PNP Archive', 'Item Location Inventory')
                }
              },
              {
                label: 'Parts by Bin Location', icon: 'c-icons idms-routing-master-search-icon',
                title: 'IDMS Reference: IA01.', command: () => {
                  this.navigateByUrl('/idms/item-master-reports-main/parts-bin-location',
                    'CICS - PNP Archive', 'Parts by Bin Location')
                }
              },

            ]
          },
          {
            label: 'Bill of Materials', expanded: this.idmsSubModulesNavigation.isBOMExpanded, command: () => { this.onSubModuleExpand("IDMS Bill of Materials"); },
            items: [
              { label: 'Item Number LookUp', icon: 'c-icons idms-bom-item-number-lookup-icon', title: 'IDMS Reference: XXXX.', command: () => { this.navigateByUrl('/idms/bill-of-materials/bom-item-number-lookup', 'CICS - PNP Archive', 'Item Number LookUp') } },
              { label: 'P/S Inquiry Multi Level', icon: 'c-icons idms-ps-inquiry-multilevel-icon', title: 'IDMS Reference: BMML.', command: () => { this.navigateByUrl('/idms/bill-of-materials/ps-multilevel', 'CICS - PNP Archive', 'P/S Inquiry Multi Level') } },
              { label: 'P/S Inquiry First Level', icon: 'c-icons idms-ps-inquiry-firstlevel-icon', title: 'IDMS Reference: BMSL.', command: () => { this.navigateByUrl('/idms/bill-of-materials/ps-inquiry-firstlevel', 'CICS - PNP Archive', 'P/S Inquiry First Level') } },
              { label: 'P/S Inquiry Item Where Used', icon: 'c-icons idms-ps-inquiry-item-whreused-icon', title: 'IDMS Reference: BMWU.', command: () => { this.navigateByUrl('/idms/bill-of-materials/ps-inquiry-item-whereused', 'CICS - PNP Archive', 'P/S Inquiry Item WhereUsed') } },
              // { label: 'P/S Multi Level', icon: 'c-icons ees-bom-comments-icon', title: 'IDMS Reference: -', command: () => { this.navigateByUrl('/idms/bill-of-materials/ps-multilevel', 'CICS - PNP Archive', 'P/S Multi Level') } },
            ]
          },
          {
            label: 'Engineering Change', expanded: this.idmsSubModulesNavigation.isEngineeringChangeExpanded, command: () => { this.onSubModuleExpand("IDMS Engineering Change"); },
            items: [
              { label: 'LookUp', icon: 'c-icons idms-ec-lookup-icon', title: 'IDMS Reference: BMBE.', command: () => { this.navigateByUrl('/idms/engineering-change/ec-lookup', 'CICS - PNP Archive', 'LookUp') } },
              { label: 'Notice', icon: 'c-icons idms-ec-notice-icon', title: 'IDMS Reference: BMEP, BMEC.', command: () => { this.navigateByUrl('/idms/engineering-change/ec-notice', 'CICS - PNP Archive', 'Notice') } },
              { label: 'Q&A', icon: 'c-icons idms-ec-qa-icon', title: 'IDMS Reference: BMQA.', command: () => { this.navigateByUrl('/idms/engineering-change/ec-qa', 'CICS - PNP Archive', 'Q&A') } },
            ]
          },
          {
            label: 'Purchasing', expanded: this.idmsSubModulesNavigation.isPurchasingExpanded, command: () => { this.onSubModuleExpand("Purchasing"); },
            items: [
              { label: 'Purchase Order History By Item', icon: 'c-icons ees-po-orderline-comments-icon', title: 'IDMS Reference: BMPH.', command: () => { this.navigateByUrl('/idms/purchasing/purchase-order-history-by-item', 'CICS - PNP Archive', 'Purchase Order History By Item') } },
              { label: 'Supplier Information', icon: 'c-icons ees-po-orderline-comments-icon', title: 'IDMS Reference: SMID.', command: () => { this.navigateByUrl('/idms/purchasing/supplier-information', 'CICS - PNP Archive', 'Supplier Information') } },
              { label: 'Supplier Text', icon: 'c-icons ees-item-master-icon', title: 'IDMS Reference: SMTX.', command: () => { this.navigateByUrl('/idms/purchasing/supplier-text', 'CICS - PNP Archive', 'Supplier Text') } },
              { label: 'Supplier Item LookUp', icon: 'c-icons idms-bom-item-number-lookup-icon', title: 'IDMS Reference: SMSI.', command: () => { this.navigateByUrl('/idms/purchasing/supplier-item-lookup', 'CICS - PNP Archive', 'Supplier Item LookUp') } },
            ]
          },
          {
            label: 'Misc Item Reports', expanded: this.idmsSubModulesNavigation.isMiscItemReportsExpanded, command: () => { this.onSubModuleExpand("Misc Item Reports"); },
            items: [
              { label: 'Preliminary Items', icon: 'c-icons ees-all-fields-icon', title: 'IDMS Reference: BMPI.', command: () => { this.navigateByUrl('/idms/misc-item-reports/preliminary-items', 'CICS - PNP Archive', 'Preliminary Items') } },
              { label: 'Drawing Where Used', icon: 'c-icons idms-ps-inquiry-item-whreused-icon', title: 'IDMS Reference: BMDW.', command: () => { this.navigateByUrl('/idms/misc-item-reports/drawing-where-used', 'CICS - PNP Archive', 'Drawing Where Used') } },
              { label: 'Item Cross References', icon: 'c-icons ees-item-manufacturing-routing-icon', title: 'IDMS Reference: BMCR.', command: () => { this.navigateByUrl('/idms/misc-item-reports/item-cross-reference', 'CICS - PNP Archive', 'Item Cross Reference') } },
              { label: 'Item Revision History', icon: 'c-icons ees-wo-details-icon', title: 'IDMS Reference: BMRV.', command: () => { this.navigateByUrl('/idms/misc-item-reports/item-revision-history', 'CICS - PNP Archive', 'Item Revision History') } },
              { label: 'Item Text', icon: 'c-icons ees-item-master-icon', title: 'IDMS Reference: BMTX.', command: () => { this.navigateByUrl('/idms/misc-item-reports/item-text', 'CICS - PNP Archive', 'Item Text') } },
            ]
          },
          {
            label: 'Tooling', expanded: this.idmsSubModulesNavigation.isToolingExpanded, command: () => { this.onSubModuleExpand("Tooling"); },
            items: [
              { label: 'Tools By Item', icon: 'c-icons ees-po-inquiry-icon', title: 'IDMS Reference: TMIT.', command: () => { this.navigateByUrl('/idms/tooling/tools-by-item', 'CICS - PNP Archive', 'Tools By Item') } },
              { label: 'NC Program Search by Asset Number', icon: 'c-icons ees-po-inquiry-icon', title: 'IDMS Reference: TMTS.', command: () => { this.navigateByUrl('/idms/tooling/nc-program-by-asset-number', 'CICS - PNP Archive', 'NC Program Search by Asset Number') } },
              { label: 'Gage Inspection History', icon: 'c-icons ees-po-inquiry-icon', title: 'IDMS Reference: TM02.', command: () => { this.navigateByUrl('/idms/tooling/gage-inspection-history', 'CICS - PNP Archive', 'Gage Inspection History') } },
              { label: 'Inventory Search by Tool', icon: 'c-icons ees-po-inquiry-icon', title: 'IDMS Reference: TM07.', command: () => { this.navigateByUrl('/idms/tooling/inventory-search-by-tool', 'CICS - PNP Archive', 'Inventory Search by Tool') } },
              { label: 'NC Program Revision History', icon: 'c-icons ees-po-inquiry-icon', title: 'IDMS Reference: TMTH.', command: () => { this.navigateByUrl('/idms/tooling/nc-program-revision-history', 'CICS - PNP Archive', 'NC Program Revision History') } },
              { label: 'Serial No. Search by Tool No.', icon: 'c-icons ees-po-inquiry-icon', title: 'IDMS Reference: TM06.', command: () => { this.navigateByUrl('/idms/tooling/serial-number-search-by-tool-number', 'CICS - PNP Archive', 'Serial No. search by Tool No.') } },
              { label: 'Tool Serial No. Inquiry', icon: 'c-icons ees-po-inquiry-icon', title: 'IDMS Reference: TM11.', command: () => { this.navigateByUrl('/idms/tooling/tool-serial-no-inquiry', 'CICS - PNP Archive', 'Tool serial No. Inquiry') } },
              { label: 'Tool Profile Inquiry', icon: 'c-icons ees-po-inquiry-icon', title: 'IDMS Reference: TM14.', command: () => { this.navigateByUrl('/idms/tooling/tool-profile-inquiry', 'CICS - PNP Archive', 'Tool Profile Inquiry') } },
              { label: 'Tools by Tool Group Number', icon: 'c-icons ees-po-inquiry-icon', title: 'IDMS Reference: TM15.', command: () => { this.navigateByUrl('/idms/tooling/tools-by-tool-group-number', 'CICS - PNP Archive', 'Tools by Tool Group Number') } },
              { label: 'Tool Inquiry', icon: 'c-icons ees-po-inquiry-icon', title: 'IDMS Reference: TM28.', command: () => { this.navigateByUrl('/idms/tooling/tool-inquiry', 'CICS - PNP Archive', 'Tool Inquiry') } },
            ]
          },
          {
            label: 'Routing Reports', expanded: this.idmsSubModulesNavigation.isRoutingReportsExpanded, command: () => { this.onSubModuleExpand("Routing Reports"); },
            items: [
              { label: 'Routing Master Search', icon: 'c-icons idms-routing-master-search-icon', title: 'IDMS Reference: RTMS.', command: () => { this.navigateByUrl('/idms/routing-reports/master-search', 'CICS - PNP Archive', 'Routing Master Search') } },
              { label: 'Routing Display by Item', icon: 'c-icons idms-routing-display-item-icon', title: 'IDMS Reference: RTIN', command: () => { this.navigateByUrl('/idms/routing-reports/routing-by-item-number', 'CICS - PNP Archive', 'Routing Display by Item') } },
              { label: 'Tools Usage Interactive', icon: 'c-icons idms-routing-usage-interaction-icon', title: 'IDMS Reference: RTST', command: () => { this.navigateByUrl('/idms/routing-reports/tool-usage-interactive', 'CICS - PNP Archive', 'Tools Usage Interactive') } },
              { label: 'Tool Usage by Tool', icon: 'c-icons idms-routing-tool-usage-by-tool-icon', title: 'IDMS Reference: RTST', command: () => { this.navigateByUrl('/idms/routing-reports/tool-usage-tool', 'CICS - PNP Archive', 'Tool Usage by Tool') } },
            ]
          },
          {
            label: 'Pattern & A/P Pages', expanded: this.idmsSubModulesNavigation.isPatternAPPages, command: () => { this.onSubModuleExpand("Pattern & A/P Pages"); },
            items: [
              { label: 'Pattern Master Search', icon: 'c-icons ees-item-master-icon', title: 'IDMS Reference: PTPS, PTPL', command: () => { this.navigateByUrl('/idms/pattern-ap-pages/pattern-master-search', 'CICS - PNP Archive', 'Pattern Master Search') } },
              { label: 'Pattern Master Inquiry', icon: 'c-icons ees-po-inquiry-icon', title: 'IDMS Reference: PTMS, PTWU, PTVL, PTXT', command: () => { this.navigateByUrl('/idms/pattern-ap-pages/pattern-master-inquiry', 'CICS - PNP Archive', 'Pattern Master Inquiry') } },
              { label: 'Pattern Text Data', icon: 'c-icons ees-item-essential-details-icon', title: 'IDMS Reference: PTXT', command: () => { this.navigateByUrl('/idms/pattern-ap-pages/pattern-text-data', 'CICS - PNP Archive', 'Pattern Text Data') } },
              { label: 'Vendor List by Pattern Number', icon: 'c-icons ees-all-fields-icon', title: 'IDMS Reference: PTVL', command: () => { this.navigateByUrl('/idms/pattern-ap-pages/vendor-list-by-patter-number', 'CICS - PNP Archive', 'Vendor List by Pattern Number') } },
            ]
          },

          {
            label: 'Shop Orders', expanded: this.idmsSubModulesNavigation.isShopOrders, command: () => { this.onSubModuleExpand("Shop Orders"); },
            items: [
              { label: 'Shop Orders Interactive', icon: 'c-icons ees-po-orderline-comments-icon', title: 'IDMS Reference: SOST', command: () => { this.navigateByUrl('/idms/shop-orders/shop-orders-interactive', 'CICS - PNP Archive', 'Shop Orders Interactive') } },
              { label: 'Shop Orders for an Item', icon: 'c-icons ees-po-orderline-comments-icon', title: 'IDMS Reference: SOOQ', command: () => { this.navigateByUrl('/idms/shop-orders/shop-orders-for-item', 'CICS - PNP Archive', 'Shop Orders for an Item') } },
              { label: 'Operations by Shop Order', icon: 'c-icons ees-po-inquiry-icon', title: 'IDMS Reference: SOPM', command: () => { this.navigateByUrl('/idms/shop-orders/operations-for-shoporder', 'CICS - PNP Archive', 'Operations by Shop Order') } },
              { label: 'Shop Orders History', icon: 'c-icons ees-po-orderline-comments-icon', title: 'IDMS Reference: SOPH', command: () => { this.navigateByUrl('/idms/shop-orders/shop-order-history', 'CICS - PNP Archive', 'Shop Order Status Information') } },
              { label: 'Labor by Operation', icon: 'c-icons ees-item-essential-details-icon', title: 'IDMS Reference: SOPH', command: () => { this.navigateByUrl('/idms/shop-orders/labour-by-operation', 'CICS - PNP Archive', 'Labor by Operation') } },
              { label: 'Shop Order Status Information', icon: 'c-icons ees-po-orderline-comments-icon', title: 'IDMS Reference: SOST', command: () => { this.navigateByUrl('/idms/shop-orders/so-status-information', 'CICS - PNP Archive', 'NCM Inquiry') } },
              { label: 'NCM LookUp', icon: 'c-icons ees-all-fields-icon', title: 'IDMS Reference: NCMI', command: () => { this.navigateByUrl('/idms/shop-orders/ncm-lookup', 'CICS - PNP Archive', 'NCM LookUp') } },
              { label: 'NCM Inquiry', icon: 'c-icons ees-item-master-icon', title: 'IDMS Reference: NCMR', command: () => { this.navigateByUrl('/idms/shop-orders/ncm-inquiry', 'CICS - PNP Archive', 'NCM Inquiry') } },
            ]
          },
          {
            label: 'Customer Complaint', expanded: this.idmsSubModulesNavigation.isCustomerComplaint, command: () => { this.onSubModuleExpand("Customer Complaint"); },
            items: [
              { label: 'Lookup', icon: 'c-icons idms-bom-item-number-lookup-icon', title: 'IDMS Reference: DCIN', command: () => { this.navigateByUrl('/idms/customer-complaint/customer-complaint-lookup', 'CICS - PNP Archive', 'Lookup') } },
              { label: 'Inquiry', icon: 'c-icons ees-item-master-icon', title: 'IDMS Reference: DCCM', command: () => { this.navigateByUrl('/idms/customer-complaint/customer-complaint-inquiry', 'CICS - PNP Archive', 'Inquiry') } },
              { label: 'Assignment Details', icon: 'c-icons ees-item-essential-details-icon', title: 'IDMS Reference: XXXX', command: () => { this.navigateByUrl('/idms/customer-complaint/complaint-assignment-details', 'CICS - PNP Archive', 'Assignment Details') } },
              { label: 'Text', icon: 'c-icons ees-item-master-icon', title: 'IDMS Reference: DCTX', command: () => { this.navigateByUrl('/idms/customer-complaint/complaint-text', 'CICS - PNP Archive', 'Text') } },
            ]
          },
        ]
      },

      //IMS
      {
        label: 'CICS - IMS Archive', visible: (this.edaUser.hasOwnProperty("HASIMSACCESS_ORIG_TYPE") && this.edaUser.HASIMSACCESS_ORIG_TYPE), expanded: this.defaultIMSExpanded,
        command: () => this.onModuleClick('CICS - IMS Archive'),
        items: [
          {
            label: 'Drawing Information System',
            expanded: this.imsNavigation.isDISExpanded,
            command: () => { this.onSubModuleExpand("Drawing Information System"); },
            items: [
              {
                label: 'Drawing Inquiry', title: 'CICS Reference: xxxx', icon: 'c-icons ees-item-master-icon',
                command: () => { this.navigateByUrl('/ims/main-drawing-information-system/drawing-inquiry', 'CICS - IMS Archive', 'Drawing Inquiry') }
              },
              { label: 'Interactive Report', icon: 'c-icons ees-item-manufacturing-routing-icon', title: 'CICS Reference: xxxx', command: () => { this.navigateByUrl('/ims/main-drawing-information-system/interactive-report', 'CICS - IMS Archive', 'Interactive Report') } },
              { label: 'Revision Information', icon: 'c-icons ees-all-fields-icon', title: 'CICS Reference: xxxx', command: () => { this.navigateByUrl('/ims/main-drawing-information-system/revision-information', 'CICS - IMS Archive', 'Revision Information') } },
              { label: 'Parts by Drawing Number', icon: 'c-icons ees-item-essential-details-icon', title: 'CICS Reference: xxxx', command: () => { this.navigateByUrl('/ims/main-drawing-information-system/parts-by-drawing-number', 'CICS - IMS Archive', 'Parts by Drawing Number') } },
              { label: 'Revision Description', icon: 'c-icons ees-extended-description-icon', title: 'CICS Reference: xxxx', command: () => { this.navigateByUrl('/ims/main-drawing-information-system/revision-description', 'CICS - IMS Archive', 'Revision Description') } },
              // { label: 'Revision Disposition', icon: 'c-icons ees-item-reservation-icon', title: 'CICS Reference: xxxx.', command: () => { this.navigateByUrl('/ims/main-drawing-information-system/revision-disposition', 'CICS - IMS Archive', 'Revision Disposition') } },
            ]
          },
        ]
      },

      //NAM - CSS Archive
      {
        label: 'NAM - CSS Archive', visible: (this.edaUser.hasOwnProperty("HASNAMCSSACCESS_ORIG_TYPE") && this.edaUser.HASNAMCSSACCESS_ORIG_TYPE), expanded: this.defaultNAMCSSExpanded,
        command: () => this.onModuleClick('NAM - CSS Archive'),
        items: [
          {
            label: 'CSS Reports',
            expanded: this.namCSSNavigation.isCCSReportsExpanded,
            command: () => { this.onSubModuleExpand("CSS Reports"); },
            items: [
              {
                label: 'Customer Order LookUp', title: '', icon: 'c-icons css-order-lookup-icon',
                command: () => { this.navigateByUrl('/nam-css/main-customer-order-lookup/customer-order-lookup', 'NAM - CSS Archive', 'Customer Order LookUp') }
              },
              { label: 'Customer Order Inquiry', icon: 'c-icons css-order-inquiry-icon', title: '', command: () => { this.navigateByUrl('/nam-css/main-customer-order-lookup/customer-order-inquiry', 'NAM - CSS Archive', 'Customer Order Inquiry') } },
              { label: 'Customer Order Text', icon: 'c-icons css-order-text-icon', title: '', command: () => { this.navigateByUrl('/nam-css/main-customer-order-lookup/customer-order-text', 'NAM - CSS Archive', 'Customer Order Text') } },
              { label: 'Customer Order Line LookUp', icon: 'c-icons css-order-line-lookup-icon', title: '', command: () => { this.navigateByUrl('/nam-css/main-customer-order-line-lookup/customer-order-line-lookup', 'NAM - CSS Archive', 'Customer Order Line lookUp') } },
              { label: 'Customer Order Line Inquiry', icon: 'c-icons css-order-line-inquiry-icon', title: '', command: () => { this.navigateByUrl('/nam-css/main-customer-order-line-lookup/customer-order-line-inquiry', 'NAM - CSS Archive', 'Customer Order Line Inquiry') } },
              { label: 'Customer Order Line Text', icon: 'c-icons css-order-line-text-icon', title: '', command: () => { this.navigateByUrl('/nam-css/main-customer-order-line-lookup/customer-order-line-text', 'NAM - CSS Archive', 'Customer Order Line Text') } },
              { label: 'Customer Order Line Supply', icon: 'c-icons css-order-line-supply-icon', title: '', command: () => { this.navigateByUrl('/nam-css/main-customer-order-line-lookup/customer-order-line-supply', 'NAM - CSS Archive', 'Customer Order Line Supply') } },
              //{ label: 'Customer Information', icon: 'c-icons css-order-text-icon', title: '', command: () => { this.navigateByUrl('/nam-css/customer-information', 'NAM - CSS Archive', 'Customer Information') } },
            ]
          },
        ]
      },


      // User Admin
      {
        label: 'User Admin', visible: this.edaUser.HASADMINACCESS_ORIG_TYPE, icon: PrimeIcons.USERS, command: () => { this.navigateByUrl('/admin/users', 'Admin', 'Users'); }
      },

      // DRQuest (Submit an issue)
      {
        label: 'CP Service Desk', icon: 'c-icons cp-eng-apps-icon', command: () => {
          this.showJiraLink();
        }
      },

      // Logout
      {

        label: 'Logout', icon: PrimeIcons.SIGN_OUT, command: () => {
          this.logout();
        }
      },
    ];
    return items;
  }

  onModuleClick(val: string) {
    sessionStorage.removeItem('UserNavigatedModule');
    sessionStorage.setItem('UserNavigatedModule', val);
    this.selectedModule = this.utilityService.mapToJiraComponent(val);
    this.userNavigatedModule = val;
    this.defaultIFSExpanded = this.userNavigatedModule === "IFS" ? true : false;
    this.defaultONCEExpanded = this.userNavigatedModule === "ONCE" ? true : false;
    this.defaultAGTPortalExpanded = this.userNavigatedModule === "AGT Portal" ? true : false;
    this.defaultListenDBExpanded = this.userNavigatedModule === "Listen DB" ? true : false;
    this.defaultModmanExpanded = this.userNavigatedModule === "Modman" ? true : false;
    this.defaultEESArchiveExpanded = this.userNavigatedModule === "CICS - EES Archive" ? true : false;
    this.defaultIDMSExpanded = this.userNavigatedModule === "CICS - PNP Archive" ? true : false;
    this.defaultNAMCSSExpanded = this.userNavigatedModule === "NAM - CSS Archive" ? true : false;
    this.defaultIMSExpanded = this.userNavigatedModule === "CICS - IMS Archive" ? true : false;

    if (this.edaUser.hasOwnProperty("HASAGTPORTALACCESS_ORIG_TYPE") && this.edaUser.HASAGTPORTALACCESS_ORIG_TYPE && this.isSDRLVisible == false) {
      this.portalUser = JSON.parse(sessionStorage.getItem('sdrlPortalUser') || '{}');
      if (Object.keys(this.portalUser).length > 0) {
        this.isSDRLVisible = true;
        this.onSDRLMenuVisibilityByRoles();
        this.items = this.getItems();
      }
    }
  }


  onSubModuleExpand(val: string) {
    switch (val) {
      case "SDRL":
        this.agtPortalSubModulesNavigation.isSDRLExpanded = !this.agtPortalSubModulesNavigation.isSDRLExpanded;
        this.collapseAllSDRLSubMenus();
        break;
      case "Certification":
        this.agtPortalSubModulesNavigation.isCertificationExpanded = !this.agtPortalSubModulesNavigation.isCertificationExpanded;
        break;
      case "Change Management":
        this.agtPortalSubModulesNavigation.isChangeManagementExpanded = !this.agtPortalSubModulesNavigation.isChangeManagementExpanded;
        break;
      case "Administration":
        this.agtPortalSubModulesNavigation.isSDRLAdminExpanded = !this.agtPortalSubModulesNavigation.isSDRLAdminExpanded;
        break;
      case "Item Requests":
        this.agtPortalSubModulesNavigation.isItemRequestsExpanded = !this.agtPortalSubModulesNavigation.isItemRequestsExpanded;
        break;
      case "Document Register":
        this.agtPortalSubModulesNavigation.isDocregisterExpanded = !this.agtPortalSubModulesNavigation.isDocregisterExpanded;
        break;
      case "Configuration Control":
        this.agtPortalSubModulesNavigation.isSDRLConfigControlExpanded = !this.agtPortalSubModulesNavigation.isSDRLConfigControlExpanded;
        break;
      case "Document Control":
        this.agtPortalSubModulesNavigation.isSDRLDocControlExpanded = !this.agtPortalSubModulesNavigation.isSDRLDocControlExpanded;
        break;
      case "Reporting":
        this.agtPortalSubModulesNavigation.isSDRLReportExpanded = !this.agtPortalSubModulesNavigation.isSDRLReportExpanded;
        break;
      case "Work Orders":
        this.eesArchiveSubModulesNavigation.isWorkOrdersExpanded = !this.eesArchiveSubModulesNavigation.isWorkOrdersExpanded;
        break;
      case "Bill of Materials":
        this.eesArchiveSubModulesNavigation.isBOMExpanded = !this.eesArchiveSubModulesNavigation.isBOMExpanded;
        break;
      case "Parts Orders":
        this.eesArchiveSubModulesNavigation.isPartsOrdersExpanded = !this.eesArchiveSubModulesNavigation.isPartsOrdersExpanded;
        break;
      case "Item Master Reports":
        this.eesArchiveSubModulesNavigation.isItemMasterReportsExpanded = !this.eesArchiveSubModulesNavigation.isItemMasterReportsExpanded;
        break;
      case "IDMS Item Master Reports":
        this.idmsSubModulesNavigation.isItemMasterReportsExpanded = !this.idmsSubModulesNavigation.isItemMasterReportsExpanded;
        break;
      case "IDMS Bill of Materials":
        this.idmsSubModulesNavigation.isBOMExpanded = !this.idmsSubModulesNavigation.isBOMExpanded;
        break;
      case "IDMS Engineering Change":
        this.idmsSubModulesNavigation.isEngineeringChangeExpanded = !this.idmsSubModulesNavigation.isEngineeringChangeExpanded;
        break;
      case "Purchasing":
        this.idmsSubModulesNavigation.isPurchasingExpanded = !this.idmsSubModulesNavigation.isPurchasingExpanded;
        break;
      case "Misc Item Reports":
        this.idmsSubModulesNavigation.isMiscItemReportsExpanded = !this.idmsSubModulesNavigation.isMiscItemReportsExpanded;
        break;
      case "Tooling":
        this.idmsSubModulesNavigation.isToolingExpanded = !this.idmsSubModulesNavigation.isToolingExpanded;
        break;
      case "Routing Reports":
        this.idmsSubModulesNavigation.isRoutingReportsExpanded = !this.idmsSubModulesNavigation.isRoutingReportsExpanded;
        break;
      case "Pattern & A/P Pages":
        this.idmsSubModulesNavigation.isPatternAPPages = !this.idmsSubModulesNavigation.isPatternAPPages;
        break;
      case "Shop Orders":
        this.idmsSubModulesNavigation.isShopOrders = !this.idmsSubModulesNavigation.isShopOrders;
        break;
      case "CSS Reports":
        this.namCSSNavigation.isCCSReportsExpanded = !this.namCSSNavigation.isCCSReportsExpanded;
        break;
      case "Drawing Information System":
        this.imsNavigation.isDISExpanded = !this.imsNavigation.isDISExpanded;
        break;
      case "Customer Complaint":
        this.idmsSubModulesNavigation.isCustomerComplaint = !this.idmsSubModulesNavigation.isCustomerComplaint;
        break;
      default:
        break;
    }



    sessionStorage.removeItem('agtPortalSubModulesNavigation');
    sessionStorage.setItem('agtPortalSubModulesNavigation', JSON.stringify(this.agtPortalSubModulesNavigation));

    sessionStorage.removeItem('eesArchiveSubModulesNavigation');
    sessionStorage.setItem('eesArchiveSubModulesNavigation', JSON.stringify(this.eesArchiveSubModulesNavigation));

    sessionStorage.removeItem('imsNavigation');
    sessionStorage.setItem('imsNavigation', JSON.stringify(this.imsNavigation));

    sessionStorage.removeItem('namCSSNavigation');
    sessionStorage.setItem('namCSSNavigation', JSON.stringify(this.namCSSNavigation));

    sessionStorage.removeItem('idmsSubModulesNavigation');
    sessionStorage.setItem('idmsSubModulesNavigation', JSON.stringify(this.idmsSubModulesNavigation));

  }

  collapseAllSDRLSubMenus() {
    if (this.agtPortalSubModulesNavigation.isSDRLExpanded === false) {
      this.agtPortalSubModulesNavigation.isSDRLDocControlExpanded = false;
      this.agtPortalSubModulesNavigation.isSDRLConfigControlExpanded = false;
      this.agtPortalSubModulesNavigation.isSDRLAdminExpanded = false;
      this.agtPortalSubModulesNavigation.isSDRLReportExpanded = false;
    }
  }

  navigateByUrl(path: string, module: string, submodule: string) {
    //
    sessionStorage.removeItem("submissionFilter");
    sessionStorage.removeItem("projectListFilter");
    sessionStorage.removeItem("selectedRow");
    sessionStorage.removeItem("previousPage");
    sessionStorage.removeItem('previousView');
    sessionStorage.removeItem('kksData');
    sessionStorage.removeItem('spNameUpdateEditLayout');
    sessionStorage.removeItem('mel-filter-criteria');
    sessionStorage.removeItem('vel-filter-criteria');


    this.selectedModule = this.utilityService.mapToJiraComponent(module);
    this.display = false;

    if (this.sessionStorageValid()) {
      this.utilityService.addUserModuleLog(module, submodule);
      this.router.navigateByUrl(path);
    }

    this.validateHelpFile(module);

    //
    this.utilityService.downloadFile('CICS - PNP Archive Help.docx|||edahelpdocs');
    //this.display = false;


  }

  getSelectedText(event: any) {
    if (event.target.text) {
      return event.target.text
    }
    else if (event.target.parentElement.text) {
      return event.target.parentElement.text
    }
    else if (event.target.explicitOriginalTarget.data)
      return event.target.explicitOriginalTarget.data
  }


  myMenu(event: any) {
    event.preventDefault();
    if (event.target.tagName !== "A") {
      this.showContextMenu = false;
      return
    }
    this.showContextMenu = true;
    let selectedOption = '';
    selectedOption = this.getSelectedText(event);
    this.newTabLink = (this.routingLinks as any)[selectedOption];

    this.xAxis = event.pageX;
    this.yAxis = event.pageY;
  }

  getRightClickPosition() {

    return {
      "z-index": 1000000,
      position: "fixed",
      left: this.xAxis + 'px',
      top: this.yAxis + 'px',

    }
  }

  hideContextMenu() {
    // this.contextMenu.nativeElement.style.display = "none";
    this.showContextMenu = false;
  }

  sessionStorageValid(): boolean {
    if (sessionStorage.getItem('loggedinUser') === null) {
      if (this.router.url === "/misc/unauthorized") {
        this.unAuthorized();
      }
      else {
        this.logout();
      }
      return false;
    }
    return true;
  }

  validateHelpFile(module: string) {
    //
    sessionStorage.removeItem("helpFile");
    this.helpFileName = 'Help File';
    let helpFileInfo: any = sessionStorage.getItem('helpFiles');
    if (helpFileInfo !== null) {
      switch (module) {
        case 'idms':
        case 'CICS - PNP Archive':
          let helpFile = JSON.parse(helpFileInfo).find((item: any) => item.MODULE.toLowerCase() === module.toLowerCase());
          if (helpFile) {
            sessionStorage.setItem("helpFile", JSON.stringify(helpFile));
            this.helpDocumentPath = true;
            this.helpFileName = helpFile.MODULE + ' Help File';
          }
          break;

        default:
          sessionStorage.removeItem("helpFile");
          this.helpDocumentPath = false;
          this.helpFileName = 'Help File';
          break;
      }
    }
  }

  getTitle() {

    //adept as Adept and once as ONCE IFS
    let modulePosition = 0;
    let splitArray = window.location.href.split("/");
    if (splitArray.length === 5) {
      modulePosition = 2;
    }
    else if (splitArray.length > 5) {
      modulePosition = 3;
    }
    let currentPage = splitArray[splitArray.length - modulePosition];

    switch (currentPage) {
      case 'adept':
        return "Adept System"
      case 'once':
        return "ONCE"
      case 'ifs':
        return "IFS"
      case 'admin':
        return "User Admin"
      case 'network-data':
        return "Network Data"
      case 'agt-portal':
        return "AGT Portal"
      case 'transformer-cards':
        return "Transformer Cards"
      case 'listen-db':
        return "Listen DB"
      case 'teamcenter-archive':
        return "Teamcenter Archive"
      case 'ees-archive-system':
        return "CICS - EES Archive"
      case 'idms':
        return "CICS - PNP Archive"
      case 'nam-css':
        return "NAM - CSS Archive"
      case 'modman':
        return "Modman"
      case 'cts-drawings':
        return "CTS  Drawings"
      case 'ppt-drawings':
        return "PNP  Drawings"
      case 'ims':
        return 'CICS - IMS Archive'
    }
    return 'Login'
  }

  logout() {
    this.display = false;
    this.isMenuVisible = false;
    localStorage.clear();
    sessionStorage.clear();
    // localStorage.removeItem('okta-token-storage');
    // sessionStorage.removeItem('loggedinUser');
    this.router.navigateByUrl('/misc/loggedout');
    window.close();
  }

  unAuthorized() {
    this.display = false;
    this.isMenuVisible = false;
    // localStorage.removeItem('okta-token-storage');
    // sessionStorage.removeItem('loggedinUser');
    localStorage.clear();
    sessionStorage.clear();
  }

  onMenuClick() {
    if (this.edaUser.hasOwnProperty("HASAGTPORTALACCESS_ORIG_TYPE") && this.edaUser.HASAGTPORTALACCESS_ORIG_TYPE) {
      this.portalUser = JSON.parse(sessionStorage.getItem('sdrlPortalUser') || '{}');
      if (Object.keys(this.portalUser).length > 0) {
        this.isSDRLVisible = true;
        this.onSDRLMenuVisibilityByRoles();
      }
    }
    let userModule: any = sessionStorage.getItem('UserNavigatedModule') === null ? '' : sessionStorage.getItem('UserNavigatedModule');
    if (userModule === '') {
      userModule = this.edaUser?.DEFAULTAPP?.toString();
    }

    this.defaultIFSExpanded = userModule?.toString() === "IFS" ? true : false;
    this.defaultONCEExpanded = userModule?.toString() === "ONCE" ? true : false;
    this.defaultAGTPortalExpanded = userModule?.toString() === "AGT Portal" ? true : false;
    this.defaultListenDBExpanded = userModule?.toString() === "Listen DB" ? true : false;
    this.defaultModmanExpanded = userModule?.toString() === "Modman" ? true : false;
    this.defaultEESArchiveExpanded = userModule?.toString() === "CICS - EES Archive" ? true : false;
    this.defaultIDMSExpanded = userModule?.toString() === "CICS - PNP Archive" ? true : false;
    this.defaultNAMCSSExpanded = userModule?.toString() === "NAM - CSS Archive" ? true : false;
    this.defaultIMSExpanded = userModule?.toString() === "CICS - IMS Archive" ? true : false;

    this.items = this.getItems();
    this.display = true;
  }

  onSDRLMenuVisibilityByRoles() {

    this.isSDRLDocControlVisbile = false;
    this.isSDRLAdminVisbile = false;
    this.isSDRLProjectManagementVisbile = false;

    if (this.portalUser && this.portalUser!.roles) {
      this.isSDRLDocControlVisbile = true;
      this.isSDRLAdminVisbile = true;
      this.isSDRLProjectManagementVisbile = true;

      if (!this.portalUser.roles.includes(SDRLRole.Administrator) &&
        !this.portalUser.roles.includes(SDRLRole.ProjectManager) &&
        !this.portalUser.roles.includes(SDRLRole.Coordinator)) {
        this.isSDRLDocControlVisbile = false;
        this.isSDRLAdminVisbile = false;
        this.isSDRLProjectManagementVisbile = false;
      }

      if (!this.portalUser.roles.includes(SDRLRole.Administrator) &&
        !this.portalUser.roles.includes(SDRLRole.ProjectManager)) {
        this.isSDRLProjectManagementVisbile = false;
      }

      if (!this.portalUser.roles.includes(SDRLRole.Administrator)) {
        this.isSDRLAdminVisbile = false;
      }

    }
  }

  downloadHelpFile() {
    //
    let preSignedURL: string = "";
    let helpFileInfo: any = sessionStorage.getItem('helpFile');
    if (helpFileInfo) {
      helpFileInfo = JSON.parse(helpFileInfo);
      if (helpFileInfo.IS_HELPFILE_AVAILABLE_ORIG_TYPE) {
        //this.utilityService.downloadFile(helpFileInfo.PATH + '|||edahelpdocs');
        this.utilityService.downloadFile(helpFileInfo.PATH + '|||edahelpdocs').subscribe(
          (value) => {
            //
            preSignedURL = value;
            const link = document.createElement("a");
            link.setAttribute("download", helpFileInfo.PATH);
            link.href = preSignedURL;
            link.setAttribute("visibility", "hidden");
            link.click();

          },
          (error) => {
            if (error.status === 404) {
            }
            console.error("Error on downloading file", error);
          }
        );
      }
    }
  }

}
