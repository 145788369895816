import { environment } from 'src/environments/environment';
import { Injectable } from '@angular/core';
import { Observable, mergeMap } from 'rxjs'
import { MsalService } from '@azure/msal-angular';

export interface Token {
  expiresAt: number;
  accessToken: string;
  email: string;
}

@Injectable({
  providedIn: 'root'
})
export class OktaService {

  //private authClient: OktaAuth;
 // private msalService: MsalService;
  constructor() {

    // this.authClient = new OktaAuth(
    //   {
    //     clientId: environment.okta.clientId,
    //     issuer: environment.okta.issuer,
    //     redirectUri: environment.okta.redirectUri,
    //     responseType: 'token',
    //     pkce: false,
    //     scopes: ['openid', 'email'],
    //     tokenManager: {
    //       storage: 'sessionStorage',
    //       autoRenew: true,
    //       expireEarlySeconds: 120
    //     }
    //   }
    // );

    //this.msalService = new MsalService()
  }


  // public reNewToken() {

  //   this.authClient.tokenManager.get('accessToken')
  //     .then((existingToken: any) => { //The token object will be returned if it has not expired.
  //       if (existingToken) {
  //         this.authClient.token.renew(existingToken).then((newToken: any) => {
  //           console.debug(newToken);
  //         })
  //         //observer.next(existingToken);


  //       } else {
  //         console.debug("getToken: Not Available");
  //         //observer.error("invalid token");
  //       }

  //     }).catch((e: any) => {
  //       if (e.status === 403) {
  //         this.reNewToken();
  //       }
  //       console.error("Error while getting token from tokenManager");
  //       this.loginUsingIdp();
  //       // observer.error(e);
  //     })


  // }


  public loginUsingIdp(): any {
    // return this.authClient.token.getWithRedirect(
    //   { idp: environment.okta.idp }
    // );
  }


  // public forceCredentiansLogIn() {
  //   return this.authClient.signInWithRedirect();
  //   //return "https://ps-siemens.oktapreview.com/sso/saml2/0oa17wfobm2MmmFnr0h8?fromURI=/home/oidc_client/0oazyv8swiCk2e9130h7/aln5z7uhkbM6y7bMy0g7"
  // }

  // public getToken(): Observable<AccessToken> {
  //   return new Observable((observer) => {

  //     this.authClient.token.getWithoutPrompt({
  //     }).then((tokenResponse: TokenResponse) => {
  //       let token = tokenResponse.tokens.accessToken as any;
  //       if (token != undefined) {

  //         let decodedToken = this.authClient.token.decode(token.accessToken) as any;
  //         if (decodedToken['payload']) {
  //           token['email'] = decodedToken.payload['sub'];
  //           token['firstName'] = decodedToken.payload['firstName'];
  //           token['lastName'] = decodedToken.payload['lastName'];
  //         }
  //         console.debug("getToken: Storing Okta Token to tokenManager", token);
  //         this.authClient.tokenManager.add('accessToken', token); // When a token is added to the tokenManager, it is automatically renewed when it expires.
  //       }

  //       observer.next(token);
  //     }).catch((e: any) => {
  //       console.error("Error while getting token without prompt", e)
  //       //this.authClient.getWithRedirect();
  //       this.loginUsingIdp();
  //       //this.forceCredentiansLogIn();
  //       observer.error(e);
  //     })

  //   })
  // }


  async signout(url?: string) {
    // console.info("signout");
    // try {
    //   this.authClient.tokenManager.clear();

    //   await this.authClient.signOut({
    //     revokeAccessToken: true,
    //     postLogoutRedirectUri: (url ? url : '/')
    //   });
    // } catch (err) {
    //   console.error('Logout error ', err);
    // }

  }
}
