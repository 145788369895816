<div class="text-center main-div">
  <br> <br> <br> <br> <br> <br> <br> <br> <br>
  <br> <br>
  <p><strong>404</strong></p>
  <p><strong>Page not found!</strong></p>
  <p>The link could not be found.</p>
  <div *ngIf="module && submodule">
    <p>Module: {{module}}</p>
    <p>Submodule: {{submodule}}</p>
  </div>
  <p>Please submit this issue by using CP Service Desk link from the navigation menu.</p> 
</div>
