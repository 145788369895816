import { SdrlSecurityService } from './../../agt-portal/services/sdrl-security.service';


import { EDAUser } from '../../admin/models/admin.model';
import { UtilityService } from '../../shared/services/utility.service';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { CommandType, IRelationalDataDto, ParameterDirection, RelationalDatabases } from '../../shared/model/shared.model';
import { MysqlService } from '../../shared/services/mysql.service';
import { ListenDbService } from '../../listen-db/services/listen-db.service';
import { ListeUserDetails } from '../../listen-db/model/listen-db.model';
import { Subject, takeUntil } from 'rxjs';
import { ActivatedRoute } from '@angular/router';

@Injectable({
  providedIn: 'root'
})
export class AuthorizationService {

  public BASE_URL: string = environment.BASE_URL;
  protected ngUnsubscribe: Subject<void> = new Subject<void>();
  public edaUser: EDAUser = {};
  headerDict = {
    'content-type': 'application/json'
  }

  constructor(private http: HttpClient, private router: ActivatedRoute, private sdrlSecurityService: SdrlSecurityService, private utilityService: UtilityService, public mysqlService: MysqlService, private listenDbService: ListenDbService) {
  }

  public getHelpFileInfo() {

    const headers = this.headerDict;
    return this.http.get<any>(this.BASE_URL + '/eda/Authorization/Get', { headers });
  }

  public getLoggedinUser() {

    const headers = this.headerDict;
    return this.http.get<any>(this.BASE_URL + '/eda/Authorization/GetLoggedInUserInfo', { headers });
  }

  public getDefualtAppForUser() {

    const headers = this.headerDict;
    return this.http.get<any>(this.BASE_URL + '/eda/Authorization/GetDefualtAppForUser', { headers });
  }

  isAuthorizedUser() {
    //sessionStorage.removeItem("loggedinUser");
    this.getLoggedinUser().subscribe((data: any) => {
      if (data !== null && data !== '') {
        if (data.documents.length > 0) {
          this.edaUser = data.documents[0];
          sessionStorage.setItem('loggedinUser', this.utilityService.getEncryptedLoggedinUserDetails(this.edaUser));
          if (this.edaUser.HASAGTPORTALACCESS_ORIG_TYPE === true) {
            this.sdrlSecurityService.getPortalUserbyEmail(this.edaUser.EMAILID!);
          }
          let hasListenDBUser: boolean = true;

          if (sessionStorage.getItem("listenDBUser") === null) {
            hasListenDBUser = false;
          }
          if (this.edaUser.HASLISTENDBACCESS_ORIG_TYPE === true && !hasListenDBUser) {

            this.listenDbService.GetUserRoles(this.edaUser.EMAILID!).pipe(takeUntil(this.ngUnsubscribe))
              .subscribe((res: any) => {
                let listeUserDetails = new ListeUserDetails();
                if (typeof res != 'undefined' && res.outputParamValue === true) {
                  listeUserDetails.firstName = res.records[0].vorname;
                  listeUserDetails.lastName = res.records[0].nachname;
                  listeUserDetails.zid = res.records[0].gid;
                  listeUserDetails.isreadwriteuser = res.records[0].isreadwriteuser === 1 ? true : false;
                  listeUserDetails.standort = res.records[0].standort;
                  listeUserDetails.personaL_NR = res.records[0].personaL_NR;
                }
                sessionStorage.setItem('listenDBUser', JSON.stringify(listeUserDetails));
              });
          }
          // this.hasJiraAvailable();
        }
        else {

          sessionStorage.removeItem('loggedinUser');
        }
      }
    },
      (error: any) => {
        console.log('error ', error);
      }
    );

  }

  //This is required if user not using callback and directly acessing module
  addUserLogIfUserAccessingUsingUrl() {
    this.getLoggedinUser().subscribe((data: any) => {
      if (data !== null && data !== '') {
        let modulePosition = 0;
        let splitArray = window.location.href.split("/");
        if (splitArray.length === 5) {
          modulePosition = 2;
        }
        else if (splitArray.length > 5) {
          modulePosition = 3;
        }
        let currentPage = splitArray[splitArray.length - modulePosition];
        this.utilityService.addUserModuleLog(currentPage, this.utilityService.getSubmodule(currentPage));
      }
    });
  }



  hasAccess(role: string): boolean {
    if (sessionStorage.getItem('loggedinUser') == null) {
      this.isAuthorizedUser();
    }
    var edaUser: EDAUser = JSON.parse(this.utilityService.getDecryptedLoggedinUserDetails() || '{}');
    let hasAccess: boolean = false;
    switch (role) {
      case 'admin': {
        hasAccess = edaUser.HASADMINACCESS_ORIG_TYPE ? true : false;

        break;
      }
      case 'adept': {

        hasAccess = edaUser.HASADEPTACCESS_ORIG_TYPE ? true : false;
        break;
      }
      case 'ifs': {
        hasAccess = edaUser.HASIFSACCESS_ORIG_TYPE ? true : false;
        break;
      }
      case 'once': {
        hasAccess = edaUser.HASONCEACCESS_ORIG_TYPE ? true : false;
        break;
      }
      case 'network-data': {
        hasAccess = (edaUser.HASNETWORKDATAUSAACCESS_ORIG_TYPE || edaUser.HASNETWORKDATACANADAACCESS_ORIG_TYPE
          || edaUser.HASNETWORKDATAFRANCEACCESS_ORIG_TYPE || edaUser.HASNETWORKDATAGERMANYACCESS_ORIG_TYPE
          || edaUser.HASNETWORKDATANORWAYACCESS_ORIG_TYPE) ? true : false;
        break;
      }
      case 'transformer-cards': {
        hasAccess = edaUser.HASTRANSFORMERCARDSACCESS_ORIG_TYPE ? true : false;
        break;
      }
      case 'teamcenterarchive': {
        hasAccess = edaUser.HASTEAMCENTERARCHIVEACCESS_ORIG_TYPE ? true : false;
        break;
      }
      case 'agt-portal': {
        hasAccess = edaUser.HASAGTPORTALACCESS_ORIG_TYPE ? true : false;
        break;
      }
      case 'listen-db': {
        hasAccess = edaUser.HASLISTENDBACCESS_ORIG_TYPE ? true : false;
        break;
      }
      case 'modman': {
        hasAccess = edaUser.HASMODMANACCESS_ORIG_TYPE ? true : false;
        break;
      }
      case 'ees-archive-system': {
        hasAccess = edaUser.HASEESARCHIVESYSTEMACCESS_ORIG_TYPE ? true : false;
        break;
      }
      case 'idms': {
        hasAccess = edaUser.HASIDMSACCESS_ORIG_TYPE ? true : false;
        break;
      }
      case 'nam-css': {
        hasAccess = edaUser.HASNAMCSSACCESS_ORIG_TYPE ? true : false;
        break;
      }
      case 'ims': {
        hasAccess = edaUser.HASIMSACCESS_ORIG_TYPE ? true : false;
        break;
      }
      case 'cts-drawings': {
        hasAccess = edaUser.HASCTSDRAWINGSACCESS_ORIG_TYPE ? true : false;
        break;
      }
      case 'ppt-drawings': {
        hasAccess = edaUser.HASPPTDRAWINGSACCESS_ORIG_TYPE ? true : false;
        break;

      }
      case 'gas-services-archive': {
        hasAccess = edaUser.HASGASSERVICESACCESS_ORIG_TYPE ? true : false;
        break;

      }


      case 'callback': {
        hasAccess = true;
        break;
      }
    }
    return hasAccess;
  }
}
