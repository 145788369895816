import { PaginatorModule } from 'primeng/paginator';
 

export enum SDRLRole
{
    Guest = 0,
    Coordinator = 1,
    ProjectManager = 2,
    Administrator = 3
}

export interface ISDRLPortalUser {
    userName: string;
    fullName: string;
    email: string;
    phone: string;
    department: string;
    title: string;
    office: string;
    roles: SDRLRole[];
}
