
import { NgModule } from '@angular/core';
import { AppRoutingModule } from './app-routing.module';
import { ReactiveFormsModule } from '@angular/forms';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NoopAnimationsModule } from '@angular/platform-browser/animations';
import { AppComponent } from './app.component';
import { primeNgModule } from './main/shared/modules/shared/primeNg.module';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { FooterComponent } from './main/shared/components/footer/footer.component';
import { HeaderComponent } from './main/shared/components/header/header.component';
import { LayoutComponent } from './main/shared/components/layout/layout.component';
import { OktaInterceptor } from './main/authorization/services/okta.intercerptor';
import { NgZoneHttpInterceptor } from './main/authorization/services/ngZone.intercerptor';
import { HeaderFormatPipe } from './main/shared/pipes/header-format.pipe';
import { environment } from '../environments/environment';
import { UserResolver } from './main/authorization/services/user.resolver';
import { AuthorizationModule } from './main/authorization/modules/authorization.module';
import { ContextMenuComponent } from './main/shared/components/context-menu/context-menu.component';
import { NotFoundComponent } from './main/shared/components/not-found/not-found.component';
import { LdaInterceptorInterceptor } from './lda-interceptor.interceptor';
import { UnauthorizedPageComponent } from './main/misc/components/unauthorized-page/unauthorized-page.component';
import { MsalGuard, MsalModule, MsalRedirectComponent, MsalInterceptor } from '@azure/msal-angular';
import { InteractionType, PublicClientApplication } from '@azure/msal-browser';
import { PersistStateService } from './main/shared/services/persist-state.service';







@NgModule({
  declarations: [
    // CallbackComponent,
    // LoginComponent,
    AppComponent,
    FooterComponent,
    HeaderComponent,
    LayoutComponent,
    ContextMenuComponent,
    NotFoundComponent,
    UnauthorizedPageComponent,
  ],
  imports: [
    AppRoutingModule,
    ReactiveFormsModule,
    // CommonModule,
    AuthorizationModule,
    BrowserModule,
    HttpClientModule,
    primeNgModule,
    BrowserAnimationsModule,
    NoopAnimationsModule,
    MsalModule.forRoot(
      new PublicClientApplication(environment.azureConfig),
      {
        interactionType: InteractionType.Redirect,
        authRequest: {
          scopes: ['user.read']
        }
      },
      {
        interactionType: InteractionType.Redirect,
        protectedResourceMap: new Map([
          ['/', environment.backEndScope],
        ])//<'', null>
      }
    ),
    // ProgressSpinnerModule
  ],

  providers: [
    environment.production ? { provide: HTTP_INTERCEPTORS, useClass: OktaInterceptor, multi: true } : [],
    {
      provide: HTTP_INTERCEPTORS,
      useClass: NgZoneHttpInterceptor,
      multi: true,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: LdaInterceptorInterceptor,
      multi: true,
    },
    // {
    //   provide: HTTP_INTERCEPTORS,
    //   useClass: OktaInterceptor,
    //   multi: true,
    // },
    HeaderFormatPipe,
    UserResolver,
    MsalGuard,
  ],
  bootstrap: [AppComponent, MsalRedirectComponent],
})
export class AppModule { }
