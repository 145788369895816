
import { AuthorizationService } from './authorization.service';
import { Observable } from 'rxjs';
import { Injectable } from "@angular/core";
import { ActivatedRouteSnapshot, Resolve, Router, RouterStateSnapshot } from "@angular/router";
import { EDAUser } from '../../admin/models/admin.model';
import { of } from 'rxjs';
import { UtilityService } from '../../shared/services/utility.service';


@Injectable({
    providedIn: 'root'
})

export class UserResolver implements Resolve<EDAUser> {
    constructor(private authorizationService : AuthorizationService, private router: Router,private utilityService:UtilityService){}
    resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<EDAUser> {
        let edaUser! : EDAUser ;
        if(sessionStorage.getItem('loggedinUser')  == null)
        {
        this.authorizationService.getLoggedinUser().subscribe((data) =>  {
            if(data !== null && data !== '')
            {
              if(data.documents.length > 0)
              {
                  edaUser =  data.documents[0];
                  sessionStorage.setItem('loggedinUser', this.utilityService.getEncryptedLoggedinUserDetails(edaUser));
                  return edaUser;
              }
            }

             return null;
          },
          error => {
            console.log('error ' ,error);
            return null;
          });

        }
        else
        {
            edaUser = JSON.parse(this.utilityService.getDecryptedLoggedinUserDetails() || '{}');
            return  of(edaUser);
        }
        return of({});
    }
}
