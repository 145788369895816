import { Component, Input, OnInit, Output,EventEmitter } from '@angular/core';


@Component({
  selector: 'app-context-menu',
  templateUrl: './context-menu.component.html',
  styleUrls: ['./context-menu.component.css']
})
export class ContextMenuComponent implements OnInit {

  constructor() { }

  @Output() openNewTab = new EventEmitter();
  @Input() newTabLink!:string;
  ngOnInit(): void {
  }

  changeRoute() {
    // this.openNewTab.emit();
    window.open(this.newTabLink, "_blank");
  }

}
