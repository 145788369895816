import { Injectable, NgZone } from '@angular/core';
import { HttpInterceptor, HttpEvent, HttpHandler, HttpRequest, HttpErrorResponse } from '@angular/common/http';
import { Observable, Observer, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';


@Injectable()
export class NgZoneHttpInterceptor implements HttpInterceptor {

  constructor(private _ngZone: NgZone) { }

  private enterZone<T>(zone: NgZone) {
    return (source: Observable<T>) => {
      return new Observable((sink: Observer<T>) => {
        return source.subscribe({
          next(x) { zone.run(() => sink.next(x)); },
          error(e) { zone.run(() => sink.error(e)); },
          complete() { zone.run(() => sink.complete()); }
        });
      });
    };
  }

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return this._ngZone.run(() => {
      return next.handle(req).pipe(
        this.enterZone(this._ngZone),
        catchError((error: HttpErrorResponse) => {
          let errorMessage = '';
          if (error.error instanceof ErrorEvent) {
            // client-side error
            errorMessage = `Error: ${error.error.message}`;
          } else {
            // server-side error
            return throwError(error);
          }

          return throwError(errorMessage);
        })


      );
    });
  }
}
