import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { primeNgModule } from '../../shared/modules/shared/primeNg.module';
import { AuthorizationRoutingModule } from './authorization-routing.module';
import { CallbackComponent } from '../components/callback/callback.component';
import { LoginComponent } from '../components/login/login.component';
import {ProgressSpinnerModule} from 'primeng/progressspinner';

@NgModule({
  imports: [AuthorizationRoutingModule, CommonModule, primeNgModule, ProgressSpinnerModule],
  exports: [],
  declarations: [CallbackComponent, LoginComponent],
  providers: [],
})
export class AuthorizationModule {}
