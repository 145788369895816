<div class="main-header">
  <div class="main-menu" *ngIf="isMenuVisible">

    <button type="button" pButton pRipple icon="pi pi-bars" (click)="onMenuClick()"></button>
    <!-- (contextmenu)="myMenu($event)" (click)="hideContextMenu()" -->
    <p-sidebar [autoZIndex]="true" [(visible)]="display">
      <b style="font-size: small;margin-left: 15px;color: rgb(97, 13, 175);"> Modules </b>
      <br>
      <br>
      <div>
        <app-context-menu *ngIf="showContextMenu" [ngStyle]="getRightClickPosition()" [newTabLink]="newTabLink">
        </app-context-menu>
        <p-panelMenu #navigationMenu styleClass="menucus" [style]="{'width':'auto'}" [model]="items"></p-panelMenu>
      </div>

    </p-sidebar>

  </div>

  <!-- <img class="logo" src="../../../../../assets/img/SE_Logo.png"> -->
  <a class="header__logo" href="" target="_blank">
    <img src="../../../../../assets/svgs/se-logo.svg" alt="Siemens Energy Logo" />
  </a>

  <div class="Project-name">
    <p class="project-name">Engineering Data Archive Solutions</p>
    <h2 class="module-name"> {{title}} </h2>
  </div>
  <p class="version-type" *ngIf="headerLabel.length !== 0 ">{{ headerLabel }}</p>
  <div class="user-name">
    <a (click)="downloadHelpFile()" *ngIf="helpDocumentPath">
      <i class="pi pi-question-circle help" title="{{helpFileName}}"></i>
    </a>
    <img class="user-icon" src="../../../../../assets/svgs/user.svg" width="24" height="24" alt="">
    <span> {{userinfo.firstName + ' ' + userinfo.lastName}}</span>
  </div>
</div>