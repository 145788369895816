import { Time } from "@angular/common";

export class MasterProjectData {
  erz: string = '';
  werK_NR: string = '';
  projektname: string = '';
  spracH_KZ: string = '';
  bemerkung: string = '';
  dokU_ART: string = '';
  maX_IND_AL: number = 0;
  dokU_NR_AL: number = 0;
  maX_IND_MEL: number = 0;
  dokU_NR_MEL: number = 0;
  maX_IND_VEL: number = 0;
  dokU_NR_VEL: number = 0;
  maX_IND_APL: number = 0;
  dokU_NR_APL: number = 0;
  maX_IND_AGS: number = 0;
  dokU_NR_AGS: number = 0;
  maX_IND_ETV: number = 0;
  dokU_NR_ETV: number = 0;
  maX_IND_KL: number = 0;
  dokU_NR_KL: number = 0;
  maX_IND_RAPL: number = 0;
  dokU_NR_RAPL: number = 0;
  maX_IND_RSL: number = 0;
  dokU_NR_RSL: number = 0;
  maX_IND_SCHM: number = 0;
  dokU_NR_SCHM: number = 0;
  maX_IND_SCHL: number = 0;
  dokU_NR_SCHL: number = 0;
  maX_IND_KKS: number = 0;
  projekT_KZ: string = '';
  sigcount: number = 0;
  maincontractnumber: string = '';
  projectvariant: string = '';
  funktionsbereich: string = '';
  maX_IND: number = 0;
  stamM_KZ: string = '';
  rauM_UEBERTRAG: string = '';
  block: string = '';
  infO_TEXT: string = '';
  msR_VERW_UEBERTRAG: string = '';
  kpF_ORIGIN_KEY: string = '';
  kpF_FUNCTION_AREA: string = '';
  reporT_TEXT: string = '';
  aL_REPORT_TEXT: string = '';
  loaD_REPORT_TEXT: string = '';
  actR_REPORT_TEXT: string = '';
  meL_REPORT_TEXT: string = '';
  schL_REPORT_TEXT: string = '';
  etV_REPORT_TEXT: string = '';
  rapL_REPORT_TEXT: string = '';
  rsL_REPORT_TEXT: string = '';
  comP_REPORT_TEXT: string = '';
  freidaT_KKS: string = '';
  freidaT_AL: string = '';
  freidaT_MEL: string = '';
  freidaT_VEL: string = '';
  freidaT_APL: string = '';
  freidaT_AGS: string = '';
  freidaT_ETV: string = '';
  freidaT_KL: string = '';
  freidaT_RAPL: string = '';
  freidaT_RSL: string = '';
  freidaT_SCHM: string = '';
  freidaT_SCHL: string = '';
  rauM_UEBERTRAG_bool: boolean = false;
  msR_VERW_UEBERTRAG_bool: boolean = false;
  insert: boolean = false;
  message: string = '';
}

export class MasterProjectKKSCount {
  ikks: number = 0;
  ikksohne: number = 0;
  iproz: number = 0;
}


export class CopyProjectData {
  erz_To: string = '';
  werK_NR_To: string = '';
  projektname_To: string = '';
  maX_IND_To: number = 0;
  erz_From: string = '';
  werK_NR_From: string = '';
  projektname_From: string = '';
  maX_IND_From: number = 0;
  message: string = '';
}


export class VELProjectSearchData {
  erz: string = '';
  werK_NR: string = '';
  projektname: string = '';
  maX_IND: string = '';
}

export class VelDetails {
  erz: string = '';
  werK_NR: string = '';
  stamM_KZ: string = '';
  kks: string = '';
  ind: number = 0;
  inD_AKT: number = 0;
  antriebsart: string = '';
  netzart: string = '';
  spannung: number = 0;
  toleranZ_VON: string = '';
  toleranZ_BIS: string = '';
  nennleistung: number = 0;
  benoetigtE_LEISTUNG: number = 0;
  drehzahl: number = 0;
  strom: number = 0;
  i_ANLAUF_I_NENN: number = 0;
  schaltung: string = '';
  bauforM_IM: string = '';
  schutzarT_IP: string = '';
  zuL_TEMPERATUR: number = 0;
  absteuervariante: string = '';
  abzweiG_SCHALTANLAGE: string = '';
  mV_STROMLOS: string = '';
  stecker: string = '';
  endschalteR_DE: string = '';
  endschalteR_WE: string = '';
  analoggeber: string = '';
  abschaltmomenT_AUF: number = 0;
  abschaltmomenT_ZU: number = 0;
  stellzeit: number = 0;
  geraetetyp: string = '';
  fabrikat: string = '';
  bemerkung: string = '';
  rauM_ANTRIEB: string = '';
  funktionsbereich: string = '';
  bearbeitungsabgrenzung: string = '';
  abtastzeit: string = '';
  spannungsart: string = '';
  steuerung: string = '';
  standorT_BEA: string = '';
  bearbeiter: string = '';
  datuM_BEA: string = '';
  fnull: string = '';
  bkZ_A: string = '';
  schL_A: string = '';
  geraeT_A: string = '';
  sacH_NR_A: string = '';
  einbauplatZ_A: string = '';
  bkZ_B: string = '';
  schL_B: string = '';
  geraeT_B: string = '';
  sacH_NR_B: string = '';
  einbauplatZ_B: string = '';
  bkZ_C: string = '';
  schL_C: string = '';
  geraeT_C: string = '';
  sacH_NR_C: string = '';
  einbauplatZ_C: string = '';
  bkZ_D: string = '';
  schL_D: string = '';
  geraeT_D: string = '';
  sacH_NR_D: string = '';
  einbauplatZ_D: string = '';
  bkZ_E: string = '';
  schL_E: string = '';
  geraeT_E: string = '';
  sacH_NR_E: string = '';
  einbauplatZ_E: string = '';
  bkZ_F: string = '';
  schL_F: string = '';
  geraeT_F: string = '';
  sacH_NR_F: string = '';
  einbauplatZ_F: string = '';
  bkZ_G: string = '';
  schL_G: string = '';
  geraeT_G: string = '';
  sacH_NR_G: string = '';
  einbauplatZ_G: string = '';
  veL_KZ_PROCHEM: string = '';
  sachnummer: string = '';
  baugruppe: string = '';
  poS_A: number = 0;
  poS_B: number = 0;
  fnulL_KWU: string = '';
  kkS_KWU: string = '';
  etV1: string = '';
  etV2: string = '';
  etV3: string = '';
  etV4: string = '';
  etV5: string = '';
  etV1_AUSF: string = '';
  etV2_AUSF: string = '';
  etV3_AUSF: string = '';
  etV4_AUSF: string = '';
  etV5_AUSF: string = '';
  es: string = '';
  bsT_A: string = '';
  bsT_B: string = '';
  bsT_C: string = '';
  bsT_D: string = '';
  bsT_E: string = '';
  bsT_F: string = '';
  bsT_G: string = '';
  eX_ZONE: string = '';
  zuendschutzart: string = '';
  message: string = '';
}

export class ListOfValues {
  id: number = 0;
  L_ART: string = '';
  attR_NAME: string = '';
  werte: string = '';
  wertE_TEXT: string = '';
}

export class MELProjectSearchData {
  erz: string = '';
  werK_NR: string = '';
  projektname: string = '';
  maX_IND: string = '';
  miN_IND: string = '';
}

export class MELDetails {
  abtastzeit: number = 0;
  ags: string = '';;
  ak: string = '';;
  anS_A: string = '';;
  anS_B: string = '';;
  anS_C: string = '';
  anS_D: string = '';
  anS_E: string = '';
  anS_F: string = '';
  anS_G: string = '';
  anord: string = '';;
  baugruppe: string = '';;
  bearbeiter: string = '';;
  bemerkung: string = '';;
  bkZ_A: string = '';;
  bkZ_B: string = '';;
  bkZ_C: string = '';;
  bkZ_D: string = '';;
  bkZ_E: string = '';;
  bkZ_F: string = '';;
  bkZ_G: string = '';;
  bsT_A: string = '';
  bsT_B: string = '';
  bsT_C: string = '';
  bsT_D: string = '';
  bsT_E: string = '';
  bsT_F: string = '';
  bsT_G: string = '';
  datuM_BEA: string = '';
  eX_ZONE: string = '';
  einbauplatZ_A: string = '';
  einbauplatZ_B: string = '';
  einbauplatZ_C: string = '';
  einbauplatZ_D: string = '';
  einbauplatZ_E: string = '';
  einbauplatZ_F: string = '';
  einbauplatZ_G: string = '';
  elektR_ANSCHLUSSPLAN: string = '';
  ersT_ABSPERRUNG1: string = '';
  ersT_ABSPERRUNG2: string = '';
  erz: string = '';
  es: string = '';
  fnulL_KWU: string = '';
  fnull: string = '';
  g1_EIN: string = '';
  g1_VOR: string = '';
  g2_EIN: string = '';
  g2_VOR: string = '';
  g3_EIN: string = '';
  g3_VOR: string = '';
  g_AUSLEGUNG_A: string = '';
  g_AUSLEGUNG_B: string = '';
  g_AUSLEGUNG_C: string = '';
  g_AUSLEGUNG_D: string = '';
  g_AUSLEGUNG_E: string = '';
  g_AUSLEGUNG_F: string = '';
  g_AUSLEGUNG_G: string = '';
  g_LI_A: string = '';
  g_LI_B: string = '';
  g_LI_C: string = '';
  g_LI_D: string = '';
  g_LI_E: string = '';
  g_LI_F: string = '';
  g_LI_G: string = '';
  geraeT_A: string = '';
  geraeT_B: string = '';
  geraeT_C: string = '';
  geraeT_D: string = '';
  geraeT_E: string = '';
  geraeT_F: string = '';
  geraeT_G: string = '';
  inD_AKT: number = 0;
  ind: number = 0;
  kkS_KWU: string = '';
  kks: string = '';
  kl: string = '';
  m_PLAN: string = '';
  maX_EW: number = 0.0;
  meld: string = '';
  messbereicH_EINHEIT: string = '';
  messbereichS_ANFANG: string = '';
  messbereichS_ENDE: string = '';
  miN_EW: number = 0.0;
  noR_EW: number = 0.0;
  norM_Z_NR_A: string = '';
  norM_Z_NR_B: string = '';
  norM_Z_NR_C: string = '';
  norM_Z_NR_D: string = '';
  norM_Z_NR_E: string = '';
  norM_Z_NR_F: string = '';
  norM_Z_NR_G: string = '';
  p_MAX: number = 0;
  poS_A: number = 0;
  poS_B: number = 0;
  rauM_ERST_ABSPERRUNG: string = '';
  rauM_GEBER: string = '';
  rauM_NR: string = '';
  sA_EIN: string = '';
  sA_VOR: string = '';
  sacH_NR_A: string = '';
  sacH_NR_B: string = '';
  sacH_NR_C: string = '';
  sacH_NR_D: string = '';
  sacH_NR_E: string = '';
  sacH_NR_F: string = '';
  sacH_NR_G: string = '';
  sachnummer: string = '';
  schL_A: string = '';
  schL_B: string = '';
  schL_C: string = '';
  schL_D: string = '';
  schL_E: string = '';
  schL_F: string = '';
  schL_G: string = '';
  stamM_KZ: string = '';
  standorT_BEA: string = '';
  stin: string = '';
  t_MAX: number = 0;
  tiN_A: string = '';
  tiN_B: string = '';
  tiN_C: string = '';
  tiN_D: string = '';
  tiN_E: string = '';
  tiN_F: string = '';
  tiN_G: string = '';
  unterverteileR_KENNZEICHEN: string = '';
  verwendung: string = '';
  werK_NR: string = '';
  zuendschutzart: string = '';
}


export class KKSDetails {
  ags: string = '';
  al: string = '';
  apl: string = '';
  baugruppe: string = '';
  bearbeiter: string = '';
  datuM_BEA: string = '';
  erz: string = '';
  etv: string = '';
  farbe: string = '';
  fnulL_KWU: string = '';
  fnull: string = '';
  inD_AKT: number = 0;
  inD_SYS: string = '';
  ind: number = 0;
  kkS_KWU: string = '';
  kks: string = '';
  kl: string = '';
  kurZ_TEXT_D: string = '';
  kurZ_TEXT_E: string = '';
  kurZ_TEXT_ES: string = '';
  kurZ_TEXT_F: string = '';
  kurZ_TEXT_I: string = '';
  kurZ_TEXT_POR: string = '';
  kurZ_TEXT_R: string = '';
  mel: string = '';
  rapl: string = '';
  rauM_NR: string = '';
  rsl: string = '';
  schl: string = '';
  schm: string = '';
  stamM_KZ: string = '';
  stamM_VORHANDEN: string = '';
  standorT_BEA: string = '';
  texT_1_D: string = '';
  texT_1_E: string = '';
  texT_1_ES: string = '';
  texT_1_F: string = '';
  texT_1_I: string = '';
  texT_1_POR: string = '';
  texT_1_R: string = '';
  texT_2_D: string = '';
  texT_2_E: string = '';
  texT_2_ES: string = '';
  texT_2_F: string = '';
  texT_2_I: string = '';
  texT_2_POR: string = '';
  texT_2_R: string = '';
  texT_KWU: string = '';
  texT_KWU_E: string = '';
  texT_KWU_ES: string = '';
  texT_KWU_F: string = '';
  texT_KWU_I: string = '';
  texT_KWU_POR: string = '';
  texT_KWU_R: string = '';
  vel: string = '';
  werK_NR: string = '';
  zeicH_NR: string = '';
  zeicH_NR_2: string = '';
  zustaendiG_A: string = '';
  zustaendiG_B: string = '';
  zustaendiG_C: string = '';
}


export class velRelease {
  erz: string = '';
  werK_NR: string = '';
  ind: number = 0;
  projektname: string = '';
  spracH_KZ: string = '';
  report_KZ: string = '';
  beA_DATUM: Date = new Date();
  beA_DURCH: string = '';
  geP_DATUM: Date = new Date();
  sgeP_DURCH: string = '';
  freI_DATUM: Date = new Date();
  freI_DURCH: string = '';
  abteilung: string = '';
  zind: string = '';
  zcode: string = '';
  zeichnungsnummer: string = '';
  eccn: string = '';
  uS_CONTENT: string = '';
  benennung: string = '';
  al: string = '';
  revision: string = '';
  revLetter: string = '';
  selectedDesignGroup: string = '';

  filtering: string = '';
  selectedKKSType: string = '';
  designGroup: boolean = false;
  customerKKS: boolean = false;
  standardKKS: boolean = false;
  drawingNo: boolean = false;
  orderByAGS: boolean = false;

  designGroupSortOption: string = 'off';
  customerKKSSortOption: string = 'off';
  standardKKSSortOption: string = 'off';
  drawingNumberSortOption: string = 'off';

  selectionCriteria1: string = '';
  selectionCriteria2: string = '';
  isFinal: boolean = false;
  rev: string = '';
  reportCaption = '';
}





export enum ListeType {
  VEL = 'VEL',
  MEL = 'MEL',
  KKS = 'KKS'
}


export class kksSearchData {
  erz: string = '';
  werK_NR: string = '';
  projektname: string = '';
}



interface KKSData {



  baugruppe: string,
  bearbeiter: string,
  datuM_BEA: string,
  erz: string,

  farbe: string,
  fnulL_KWU: string,
  fnull: string,
  inD_AKT: string,
  inD_SYS: string,
  ind: string,
  kkS_KWU: string,
  kks: string,

  kurZ_TEXT_D: string,
  kurZ_TEXT_E: string,
  kurZ_TEXT_ES: string,
  kurZ_TEXT_F: string,
  kurZ_TEXT_I: string,
  kurZ_TEXT_POR: string,
  kurZ_TEXT_R: string,


  etv: string,
  etv_bool: boolean,
  ags: string,
  ags_bool: boolean,
  al: string,
  al_bool: boolean,
  apl: string,
  apl_bool: boolean,
  kl: string,
  kl_bool: boolean,
  mel: string,
  mel_bool: boolean,
  rapl: string,
  rapl_bool: boolean,
  rsl: string,
  rsl_bool: boolean,
  schl: string,
  schl_bool: boolean,
  schm: string,
  schm_bool: boolean,
  vel: string,
  vel_bool: boolean,
  rauM_NR: string,
  stamM_KZ: string,
  stamM_VORHANDEN: string,
  standorT_BEA: string,
  texT_1_D: string,
  texT_1_E: string,
  texT_1_ES: string,
  texT_1_F: string,
  texT_1_I: string,
  texT_1_POR: string,
  texT_1_R: string,
  texT_2_D: string,
  texT_2_E: string,
  texT_2_ES: string,
  texT_2_F: string,
  texT_2_I: string,
  texT_2_POR: string,
  texT_2_R: string,
  texT_KWU: string,
  texT_KWU_E: string,
  texT_KWU_ES: string,
  texT_KWU_F: string,
  texT_KWU_I: string,
  texT_KWU_POR: string,
  texT_KWU_R: string,
  werK_NR: string,
  zeicH_NR: string,
  zeicH_NR_2: string,
  zustaendiG_A: string,
  zustaendiG_B: string,
  zustaendiG_C: string,
  armsT_SCHL: string,
  forM_SCHL: string,
  isHighlighted: string,
  positionInTable: string,
  requiredinput: string,
  select: string,
  stueckzahL_SCHL: string,
  werkstofF_SCHL: string,
  [key: string]: any;
}


export class ListeUserDetails {
  email: string = '';
  firstName: string = '';
  lastName: string = '';
  zid: string = '';
  isreadwriteuser: boolean = false;
  standort: string = '';
  personaL_NR: string = '';
}

export class PDFExportReportMelVel {
  unid: string = '';
  QualLevel: string = '';
  PC: string = '';
  Object: string = '';
  DCC: string = '';
  RegisterNo: string = '';
  DocumentNo1: string = '';
  DocumentNo2: string = '';
  ProtectionClass: string = '';
  releaseRevLetter: string = '';

}