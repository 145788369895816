export interface IUserLog {
    email?: string;
    name?: string;
    activityType?: string;
    module?: string;
    submodule?: string;
    documentUrl?: string;
    fileName?: string;
    documentType?: string;
}

export class UserLog implements IUserLog { 
    email?: string;
    name?: string;
    activityType?: string;
    module?: string;
    submodule?: string;
    documentUrl?: string;
    fileName?: string;
    documentType?: string;
}
