import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { from, Observable } from 'rxjs';
import { delay, map, mergeMap } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { IRelationalDataDto, CommandType, RelationalDatabases, IResponse, RelationalDataSearchResults, IResponseAdapter, mySqlAdapter, ISearchDto, ProcessType } from '../model/shared.model';
import { UtilityService } from './utility.service';
import { LdaService } from './lda.service';

@Injectable({
  providedIn: 'root'
})
export class MysqlService {


  public BASE_URL: string = environment.BASE_URL;
  constructor(private http: HttpClient, private utilityService: UtilityService, private ldaService: LdaService) { }




  // TO GET VALUES FROM

  public getFilteredRDSData(filteredOptions: IRelationalDataDto = { name: '', commandType: CommandType.StoredProcedure, parameters: [], databasename: '', from: 0 }, module: string = 'RDS'): Observable<RelationalDataSearchResults> {
    // return this.http.post<IOnce>(this.BASE_URL + '/eda/once/Get/' + value, filteredOptions, {
    filteredOptions.edaUser = this.utilityService.getSessionLoggedinUserDetails();
    return this.http.post<RelationalDataSearchResults>(this.BASE_URL + '/eda/' + module + '/GetStoredProcedureResults', filteredOptions, {


    }) // you send SP name,with or without paramers depends on SP
  }


  public getFilteredRDSDataWithOutputParam(filteredOptions: IRelationalDataDto = { name: '', commandType: CommandType.StoredProcedure, parameters: [], databasename: '', from: 0 }, module: string = 'RDS'): Observable<RelationalDataSearchResults> {
    // return this.http.post<IOnce>(this.BASE_URL + '/eda/once/Get/' + value, filteredOptions, {
    filteredOptions.edaUser = this.utilityService.getSessionLoggedinUserDetails();
    return this.http.post<RelationalDataSearchResults>(this.BASE_URL + '/eda/' + module + '/GetStoredProcedureResultsWithOutputParam',
      filteredOptions, {

    }).pipe(map(res => mySqlAdapter(res)))// you send SP name,with or without paramers depends on SP
  }


  public getScalarValue(filteredOptions: IRelationalDataDto = { name: '', commandType: CommandType.StoredProcedure, parameters: [], databasename: '' }, module: string = 'RDS'): Observable<IResponse> {
    filteredOptions.edaUser = this.utilityService.getSessionLoggedinUserDetails();
    return this.http.post<any>(this.BASE_URL + '/eda/' + module + '/GetScalarValue', filteredOptions, {
    }) // getscaler
  }

  public getOutputParameterValue(filteredOptions: IRelationalDataDto = { name: '', commandType: CommandType.StoredProcedure, parameters: [], databasename: '' }, module: string = 'RDS'): Observable<IResponse> {
    filteredOptions.edaUser = this.utilityService.getSessionLoggedinUserDetails();
    return this.http.post<any>(this.BASE_URL + '/eda/' + module + '/GetOutputParamValue', filteredOptions, {
    })  // same as getFilteredRDSData, but you get to name the output result
  }

  public ExecuteNonQuery(filteredOptions: IRelationalDataDto = { name: '', commandType: CommandType.StoredProcedure, parameters: [], databasename: '' }, module: string = 'RDS'): Observable<IResponse> {
    filteredOptions.edaUser = this.utilityService.getSessionLoggedinUserDetails();
    return this.http.post<any>(this.BASE_URL + '/eda/' + module + '/ExecuteNonQuery', filteredOptions, {
    }) // it will return a simple integer value,
  }

  public ExecuteNonQueryAndGetOutputParamValues(filteredOptions: IRelationalDataDto = { name: '', commandType: CommandType.StoredProcedure, parameters: [], databasename: '' }, module: string = 'RDS'): Observable<IResponse> {
    filteredOptions.edaUser = this.utilityService.getSessionLoggedinUserDetails();
    return this.http.post<any>(this.BASE_URL + '/eda/' + module + '/ExecuteNonQueryAndGetOutputParamValues', filteredOptions, {
    }) // it will return a simple integer value,
  }

  public startStepProcess(index: string, submodule: string, filteredOptions: ISearchDto = {}, processType: ProcessType = ProcessType.AsyncDownloads, module: string = 'RDS'): any {

    filteredOptions.edaUser = this.utilityService.getSessionLoggedinUserDetails();
    filteredOptions.index = index;

    return this.http.post<any>(this.BASE_URL + '/eda/' + module + '/StartStepFuncProcess/', filteredOptions, {
      params: {
        module: module,
        submodule: submodule,
      }
    }).pipe(delay(1), mergeMap((res: { jobId: string }) => {
      const url = this.BASE_URL + '/eda/' + module + '/GetStepFuncProcessStatus/' + res.jobId;

      return this.ldaService.getBucketUrl(url, processType);
    }));
  }
}
