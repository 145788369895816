import { ICertifications, ITitle } from './../../agt-portal/model/agt-portal.model';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { catchError, delay, finalize, map, mergeMap, repeatWhen, retryWhen, take, takeUntil, takeWhile, tap, timeout } from 'rxjs/operators';
import { ISearchDto, IResponse, IResponseAdapter, ProcessType, IRelationalDataDto, ParameterDirection, CommandType } from "../../shared/model/shared.model";
import { UtilityService } from '../../shared/services/utility.service';
import { concat, EMPTY, Observable, of, pipe, throwError } from 'rxjs';
import { IIfsIndexes } from '../../ifs/model/ifs-indexes';
import { environment } from 'src/environments/environment';
import { IUserLog } from '../../admin/models/user-log';

@Injectable({
  providedIn: 'root'
})
export class LdaService {
  public BASE_URL: string = environment.BASE_URL;

  constructor(private http: HttpClient, private utilityService: UtilityService) { }

  public getIndexes(module: string): any {
    const res = this.http.get(this.BASE_URL + '/eda/' + module + '/GetIndexes', {

      params: {
        isTest: false,
      },
    });

    switch (module) {
      case 'ifs':
        return res as Observable<IIfsIndexes>;
      default:
        return res as Observable<any>;
    }
  }

  public getExcelDownload(index: string, module: string, submodule: string, filteredOptions: ISearchDto = {}): any {

    filteredOptions.edaUser = this.utilityService.getSessionLoggedinUserDetails();

    return this.http.post<any>(this.BASE_URL + '/eda/' + module + '/GetExcelJsonFile/' + index, filteredOptions, {
      params: {
        module: module,
        submodule: submodule,
      }
    }).pipe(mergeMap((res: any) => {
      return this.http.get(res.path, {
      })
    }))

  }

  public startStepProcess(index: string, module: string, submodule: string, filteredOptions: ISearchDto = {}, processType: ProcessType = ProcessType.AsyncDownloads): any {

    filteredOptions.edaUser = this.utilityService.getSessionLoggedinUserDetails();
    filteredOptions.index = index;
    return this.http.post<any>(this.BASE_URL + '/eda/' + module + '/StartStepFuncProcess/', filteredOptions, {
      params: {
        module: module,
        submodule: submodule,
      }
    }).pipe(delay(1), mergeMap((res: { jobId: string }) => {
      const url = this.BASE_URL + '/eda/' + module + '/GetStepFuncProcessStatus/' + res.jobId;

      return this.getBucketUrl(url, processType)
    })

      // ABORTED
      // FAILED
      // RUNNING
      // SUCCEEDED
      // TIMED_OUT
    )

  }




  public getBucketUrl(url: string, processType: ProcessType): any {


    return this.http.get(url, {}).pipe(delay(8000), mergeMap((res: any) => {

      if ((res.status.value === "FAILED") || (res.status.value === "ABORTED") || (res.status.value === "TIMED_OUT")) {

        throw res.status.value;

      } else if ((res.status.value === "RUNNING")) {
        return this.getBucketUrl(url, processType);
      }
      else {
        let result$: any;

        switch (processType) {

          case ProcessType.AsyncDownloads:

            result$ = this.http.get(res.lambdaResponse.pathFile.url);

            break;

          case ProcessType.HtmlToPdf:
            result$ = this.http.get(res.lambdaResponse.pathFile.url, {
              responseType: "blob",
              headers: {
                "Accept": "application/pdf"
              }
            }).pipe(
              map(blob => {
                return {
                  blob: blob,
                  ...res.lambdaResponse.pathFile
                };
              })
            );;
            // result$ = this.http.get(res.lambdaResponse.pathFile);
            break;

          case ProcessType.PdfDownloads:

            break;

          case ProcessType.ZipDownloads:

            result$ = this.http.get(res.lambdaResponse.pathFile.url, {
              responseType: "blob",
              headers: {
                "Accept": "application/zip"
              }
            }).pipe(
              map(blob => {
                return {
                  blob: blob,
                  ...res.lambdaResponse.pathFile
                };
              })
            );

            break;
          default:

            result$ = this.http.get(res.lambdaResponse.pathFile.url).pipe(
              map(blob => {
                return {
                  blob: blob,
                  ...res.lambdaResponse.pathFile
                };
              })
            );;
        }

        return result$.pipe(delay(8000));
      }



    }),
      catchError(error => {
        console.error('Error in getBucketUrl:', error);
        return throwError(() =>
          new Error('Error in getBucketUrl:'))
      }))

  }


  public isHealthy(): any {

    return this.http.post<IResponse>(this.BASE_URL + '/healthz/live', {}).pipe(
      map((res: any) => {

        console.log(res);
        return res;
      }))
  }

  public getIndexCount(index: string, module: string, filteredOptions: ISearchDto = {}): any {

    filteredOptions.edaUser = this.utilityService.getSessionLoggedinUserDetails();

    return this.http.post<IResponse>(this.BASE_URL + '/eda/' + module + '/GetIndexCount/' + index, filteredOptions).pipe(
      map((res: any) => {
        return res.count
      }))
  }



  public downloadFilesinZip(documents: string[], outputFileName: string): Observable<any> {
    let url = this.BASE_URL + '/eda/Document/DownloadZipFiles';

    //let documents = ['ONCE/DOC/1/_DOC1.DOC', 'ONCE/DOC/1/_DOC2.DOC'];

    return this.http.post(url, documents, {
      responseType: "blob",
      headers: {
        "Accept": "application/zip"
      }
    })
  }




  public getFilteredData(index: string, module: string, filteredOptions: ISearchDto = {},
    getAll: boolean = false, from: number = 0, size: number = 30, needHits: boolean = false, rowButton: any = null): Observable<IResponse> {
    // return this.http.post<IOnce>(this.BASE_URL + '/eda/once/Get/' + value, filteredOptions, {
    filteredOptions.edaUser = this.utilityService.getSessionLoggedinUserDetails();

    return this.http.post<IResponse>(this.BASE_URL + '/eda/' + module + '/Get/' + index, filteredOptions, {
      params: {
        size: size,
        from: from,
        indexName: false,
        // strict: strict,
        getAll: getAll,
        needHits: needHits,
      },

    }).pipe(map(res => IResponseAdapter(res, rowButton)))
  }

  public getItems(tableName: string, module: string, filteredOptions: ISearchDto = {},
    getAll: boolean = false, lastEvalutedKey: string = '', limit: number = 30): Observable<IResponse> {
    // return this.http.post<IOnce>(this.BASE_URL + '/eda/once/Get/' + value, filteredOptions, {
    filteredOptions.edaUser = this.utilityService.getSessionLoggedinUserDetails();

    return this.http.post<IResponse>(this.BASE_URL + '/eda/' + module + '/Items/' + tableName, filteredOptions, {
      params: {
        // strict: strict,
        getAll: getAll,
        size: limit,
        lastEvalutedKey: lastEvalutedKey,
      },

    })
    // .pipe(map(res => IResponseAdapter(res)))
  }

  public getItemsCount(tableName: string, module: string, filteredOptions: ISearchDto = {}): Observable<number> {
    filteredOptions.edaUser = this.utilityService.getSessionLoggedinUserDetails();

    return this.http.post<number>(this.BASE_URL + '/eda/' + module + '/ItemsCount/' + tableName, filteredOptions, {

    })
  }

  public getDistinctData(index: string, module: string, filteredOptions: ISearchDto = {}, needHits: boolean = false,): Observable<IResponse> {
    // return this.http.post<IOnce>(this.BASE_URL + '/eda/once/Get/' + value, filteredOptions, {
    filteredOptions.edaUser = this.utilityService.getSessionLoggedinUserDetails();

    return this.http.post<IResponse>(this.BASE_URL + '/eda/' + module + '/GetDistinctValues/' + index, filteredOptions, {
      params: {
        size: 0,
        indexName: false,
        // strict: strict,
        needHits: needHits,
      },

    }).pipe(map(res => IResponseAdapter(res)))
  }

  public addUserLog(userLog: IUserLog) {
    return this.http.post<IResponse>(this.BASE_URL + '/eda/Admin/AddUserLog', userLog);
  }

  // ** Cert ADD, UPDATE, DELETE **//
  public addCertificationDetails(certifications: ICertifications) {
    return this.http.post<IResponse>(this.BASE_URL + '/eda/AgtCert/cert/add', certifications);
  }

  public UpdateCertificationDetails(certifications: ICertifications) {
    return this.http.post<IResponse>(this.BASE_URL + '/eda/AgtCert/cert/update', certifications);
  }

  public DeleteCertificationDetails(certID: string) {
    return this.http.delete<IResponse>(this.BASE_URL + '/eda/AgtCert/cert/delete/' + certID);
  }

  // ** Title ADD, UPDATE, DELETE **//
  public addCertificationTitleDetails(titleDetails: ITitle) {

    return this.http.post<IResponse>(this.BASE_URL + '/eda/AgtCert/title/add', titleDetails);
  }

  public UpdateCertificationTitleDetails(titleDetails: ITitle) {
    return this.http.post<IResponse>(this.BASE_URL + '/eda/AgtCert/title/update', titleDetails);
  }

  public DeleteCertificationTitleDetails(titleID: string) {
    return this.http.delete<IResponse>(this.BASE_URL + '/eda/AgtCert/title/delete/' + titleID);
  }

}


