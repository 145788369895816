const isIE = window.navigator.userAgent.indexOf('MSIE ') > -1 || window.navigator.userAgent.indexOf('Trident/') > -1;

export const azureConfig = {
  auth: {
    clientId: '09820a5a-b4eb-4edc-b11a-d9edd99c5294', // Application (Client) ID from the app registration
    //clientId: "4dfa59fa-1847-4afe-b366-dbb7554a5a24",
    authority: 'https://login.microsoftonline.com/254ba93e-1f6f-48f3-90e6-e2766664b477/', // The Azure cloud instance and the app's sign-in audience (tenant ID, common, organizations, or consumers)
    //authority: "https://login.microsoftonline.com/f7102e8c-8a89-414e-9eb0-3e32cd81d1eb",
    //redirectUri: 'http://localhost:4200',// This is your redirect URI,
    redirectUri: 'https://eda.mosaic-dev.siemens-energy.cloud',// This is your redirect URI,
    //redirectUri: 'https://test-app-cp-loc-dev-1.apps.prod1.css.siemens-energy.com',
    //Audience: "api://6791e6a2-0871-4fba-b844-21c94fd57bae",
    Audience: "5af1159a-7066-4d92-b56c-48ab654592e1"
  },
  cache: {
    cacheLocation: 'localStorage',
    storeAuthStateInCookie: isIE, // Set to true for Internet Explorer 11
  }
};


export const environment = {
  production: true,
  BASE_URL: 'https://api.eda-se.mosaic-dev.siemens-energy.cloud/v1',
  //BASE_URL: 'https://localhost:5001',
  backEndScope: ["api://5af1159a-7066-4d92-b56c-48ab654592e1/User.Read"],
  // BASE_URL: 'https://api.eda.mosaic-dev.siemens-energy.cloud/v1',
  //BASE_URL: 'https://localhost:5001',
  Title: 'Engineering Data Archive Solutions (Demo)',
  Version: 'Version 3.5.11',
  Release_Date: ' 07-NOV-2024 ',
  Header_Label: 'Development',
  DRQuest_URL: 'http://usoancw203web.ad101.siemens-energy.net:5000/#/submit-issue/Engineering Data Archive',
  Jira_URL: 'https://ushouamw195web.ad101.siemens-energy.net:5001/Default.aspx?Name=CPEDA&Env=Development',
  ExcludeFromInterceptors: "https://ipr-dev-fra-plm-documents.s3.eu-central-1.amazonaws.com/EDAProcess/",
  azureConfig: {
    auth: {

      clientId: '09820a5a-b4eb-4edc-b11a-d9edd99c5294', // Application (Client) ID from the app registration
      //clientId: "4dfa59fa-1847-4afe-b366-dbb7554a5a24",
      authority: 'https://login.microsoftonline.com/254ba93e-1f6f-48f3-90e6-e2766664b477', // The Azure cloud instance and the app's sign-in audience (tenant ID, common, organizations, or consumers)
      //authority: "https://login.microsoftonline.com/f7102e8c-8a89-414e-9eb0-3e32cd81d1eb",
      //redirectUri: 'http://localhost:4200',// This is your redirect URI,
      redirectUri: 'https://eda-se.mosaic-dev.siemens-energy.cloud',
      //redirectUri: 'https://test-app-cp-loc-dev-1.apps.prod1.css.siemens-energy.com',
      //Audience: "api://6791e6a2-0871-4fba-b844-21c94fd57bae",
      Audience: "5af1159a-7066-4d92-b56c-48ab654592e1"


    },
    cache: {
      cacheLocation: 'localStorage',
      storeAuthStateInCookie: isIE, // Set to true for Internet Explorer 11
    }
  }

};

